.nav.nav-tabs {
    .nav-link {
        border-left: none;
        border-top: none;
        border-right: none;
        color: #505050;
        &.active {
            background-color: transparent;
            border-bottom: solid 2px var(--secondary-color);
            font-weight: 600;
            color: var(--clickable-link-color);
        }
        &:hover {
            border-bottom: solid 2px var(--clickable-link-color);
            font-weight: 600;
            color: #505050;
            transition: border 0.3s ease-in-out;
        }
    }
}
