.post-data {
    //display: flex;
    //align-items: center;
    color: #000;
    text-decoration: none;
    cursor: pointer;

    svg {
        vertical-align: bottom;
    }

    &:hover {
        text-decoration: none;
    }

    &:hover,
    &.post-data-active {
        color: var(--primary);

        svg {
            fill: var(--primary);
        }
    }

    &.post-data-active {
        font-weight: 500;
    }

    .count-item {
        margin-left: 5px;
    }

    .comment-name {
        color: blue;
        font-size: 100px;
    }

    .no-background {
        background: none !important;
    }
}

//publish card (with textarea) on mobile
@media (max-width: 575.98px) {
    .card-publish {
        .input-group {
            flex-direction: column;

            textarea {
                width: 100%;
                padding: 0;
            }

            .input-group-append {
                justify-content: flex-end;

                .btn {
                    border-radius: 5px;
                }
            }
        }
    }
}
