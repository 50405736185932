:root {
    // Shared variables
    --scrollbar-thumb: #cecece;
    --scrollbar-thumb-hover: #b9b9b9;
}

// Shared styles
.top-bar button {
    color: var(--top-bar-font);
    background-color: var(--top-bar-background);
}

// Mobile styles with feature flag support
@media only screen and (max-width: 767px) {
    .nav-menu {
        background: var(--white);
    }
}

// Rest of the shared styles
.primary-2 {
    color: var(--ternary);
}

body {
    color: var(--body-font);
}

p {
    color: var(--paragraph-font);
}

h1 {
    color: var(--heading1-font);
}

h2 {
    color: var(--primary-color);
    font-weight: 600;
}

h3 {
    color: var(--heading3-font);
}

h4 {
    color: var(--heading4-font);
}

h5 {
    color: var(--heading5-font);
}

a {
    cursor: pointer;
    &,
    &:hover {
        color: var(--clickable-link-color);
    }
}

.publication-message-link {
    color: var(--blue) !important;
}

.clickable {
    cursor: pointer;
}

.overflow-visible {
    overflow: visible;
}

.disabled-div {
    opacity: 0.5;
    pointer-events: none;
}

.in-progress-button {
    cursor: wait;
}

.publication-message {
    p {
        margin-bottom: 0;
    }

    a {
        color: var(--blue) !important;
    }
}

.comment-content {
    p {
        margin-bottom: 0;
    }
}
