$icomoon-font-family: "icomoon" !default;
$icomoon-font-path:"/assets/fonts";

$icon-f-m-creation: "\e900";
$icon-f-animateur: "\eaec";
$icon-o-clubs: "\eaed";
$icon-f-clubs: "\eaee";
$icon-type: "\eaef";
$icon-information: "\eaf0";
$icon-f-animateur1: "\eaf1";
$icon-o-clubs1: "\eaf2";
$icon-f-clubs1: "\eaf3";
$icon-o-mention: "\eaf4";
$icon-type1: "\eaf5";
$icon-f-admin: "\eaf6";
$icon-f-bell: "\eaf7";
$icon-f-expand: "\eaf8";
$icon-f-globe: "\eaf9";
$icon-f-arrow: "\eafa";
$icon-f-like: "\eafb";
$icon-o-smile: "\eafc";
$icon-f-open: "\eafd";
$icon-f-settings: "\eafe";
$icon-f-lock: "\eaff";
$icon-f-mask: "\eb00";
$icon-f-chat: "\eb01";
$icon-f-compass: "\eb02";
$icon-f-link: "\eb03";
$icon-f-trash: "\eb04";
$icon-f-flag: "\eb05";
$icon-f-members: "\eb06";
$icon-f-label: "\eb07";
$icon-edit: "\eb08";
$icon-cetim: "\eb09";
$icon-f-edit: "\eb0a";
$icon-f-admin1: "\eb0b";
$icon-f-bell1: "\eb0c";
$icon-f-expand1: "\eb0d";
$icon-f-globe1: "\eb0e";
$icon-f-arrow1: "\eb0f";
$icon-f-like1: "\eb10";
$icon-o-smile1: "\eb11";
$icon-f-open1: "\eb12";
$icon-f-settings1: "\eb13";
$icon-f-lock1: "\eb14";
$icon-f-mask1: "\eb15";
$icon-f-chat1: "\eb16";
$icon-f-compass1: "\eb17";
$icon-f-link1: "\eb18";
$icon-f-trash1: "\eb19";
$icon-f-flag1: "\eb1a";
$icon-f-members1: "\eb1b";
$icon-f-label1: "\eb1c";
$icon-company-info: "\eb1d";
$icon-role-wizard: "\eb1e";
$icon-role-manager: "\eb1f";
$icon-contact-all: "\eb20";
$icon-burger: "\eb21";
$icon-catalogue: "\eb22";
$icon-close: "\eb23";
$icon-collaboration: "\eb24";
$icon-comments: "\eb25";
$icon-community: "\eb26";
$icon-contact: "\eb27";
$icon-copy_file: "\eb28";
$icon-down: "\eb29";
$icon-download1: "\eb2a";
$icon-file: "\eb2b";
$icon-filter1: "\eb2c";
$icon-fonction: "\eb2d";
$icon-home1: "\eb2e";
$icon-idea: "\eb2f";
$icon-info1: "\eb30";
$icon-lab1: "\eb31";
$icon-edit1: "\eb32";
$icon-lock1: "\eb33";
$icon-marker: "\eb34";
$icon-mobile1: "\eb35";
$icon-more: "\eb36";
$icon-pdf: "\eb37";
$icon-picture: "\eb38";
$icon-o-gif: "\eb39";
$icon-preview: "\eb3a";
$icon-search1: "\eb3b";
$icon-settings: "\eb3c";
$icon-shield1: "\eb3d";
$icon-sort: "\eb3e";
$icon-star: "\eb3f";
$icon-telephone: "\eb40";
$icon-trash: "\eb41";
$icon-up: "\eb42";
$icon-video: "\eb43";
$icon-world: "\eb44";
$icon-cetim1: "\eb45";
$icon-f-edit1: "\eb46";
$icon-company-info1: "\eb47";
$icon-role-wizard1: "\eb48";
$icon-role-manager1: "\eb49";
$icon-contact-all1: "\eb4a";
$icon-burger1: "\eb4b";
$icon-catalogue1: "\eb4c";
$icon-close1: "\eb4d";
$icon-collaboration1: "\eb4e";
$icon-comments1: "\eb4f";
$icon-community1: "\eb50";
$icon-date: "\eb51";
$icon-arrow-left1: "\eb52";
$icon-arrow-right1: "\eb53";
$icon-plus1: "\eb54";
$icon-save: "\eb55";
$icon-check: "\eb56";
$icon-publish: "\eb57";
$icon-my-projects: "\eb58";
$icon-annuaire: "\eb59";
$icon-annuaire-filled: "\eb5a";
$icon-wallet-filled: "\eb5b";
$icon-wallet: "\eb5c";
$icon-collaboration-filled: "\eb5d";
$icon-home-filled: "\eb5e";
$icon-idea-filled: "\eb5f";
$icon-lab-filled: "\eb60";
$icon-organization: "\eb61";
$icon-join: "\eb62";
$icon-see-participants: "\eb63";
$icon-hierarchy: "\eb64";
$icon-employees: "\eb65";
$icon-activity: "\eb66";
$icon-website: "\eb67";
$icon-contact1: "\eb68";
$icon-copy_file1: "\eb69";
$icon-down1: "\eb6a";
$icon-download4: "\eb6b";
$icon-file1: "\eb6c";
$icon-filter2: "\eb6d";
$icon-fonction1: "\eb6e";
$icon-home4: "\eb6f";
$icon-idea1: "\eb70";
$icon-info2: "\eb71";
$icon-lab2: "\eb72";
$icon-lock2: "\eb73";
$icon-marker1: "\eb74";
$icon-mobile3: "\eb75";
$icon-more1: "\eb76";
$icon-pdf1: "\eb77";
$icon-picture1: "\eb78";
$icon-o-gif1: "\eb79";
$icon-preview1: "\eb7a";
$icon-search2: "\eb7b";
$icon-settings1: "\eb7c";
$icon-shield2: "\eb7d";
$icon-sort1: "\eb7e";
$icon-star1: "\eb7f";
$icon-telephone1: "\eb80";
$icon-trash1: "\eb81";
$icon-up1: "\eb82";
$icon-video1: "\eb83";
$icon-world1: "\eb84";
$icon-date1: "\eb85";
$icon-arrow-left3: "\eb86";
$icon-arrow-right3: "\eb87";
$icon-plus2: "\eb88";
$icon-save1: "\eb89";
$icon-check1: "\eb8a";
$icon-publish1: "\eb8b";
$icon-my-projects1: "\eb8c";
$icon-annuaire1: "\eb8d";
$icon-annuaire-filled1: "\eb8e";
$icon-wallet-filled1: "\eb8f";
$icon-wallet1: "\eb90";
$icon-collaboration-filled1: "\eb91";
$icon-home-filled1: "\eb92";
$icon-idea-filled1: "\eb93";
$icon-lab-filled1: "\eb94";
$icon-organization1: "\eb95";
$icon-join1: "\eb96";
$icon-see-participants1: "\eb97";
$icon-hierarchy1: "\eb98";
$icon-employees1: "\eb99";
$icon-activity1: "\eb9a";
$icon-website1: "\eb9b";
$icon-repost: "\e700";
$icon-home: "\e901";
$icon-home2: "\e902";
$icon-home3: "\e903";
$icon-office: "\e904";
$icon-newspaper: "\e905";
$icon-pencil: "\e906";
$icon-pencil2: "\e907";
$icon-quill: "\e908";
$icon-pen: "\e909";
$icon-blog: "\e90a";
$icon-eyedropper: "\e90b";
$icon-droplet: "\e90c";
$icon-paint-format: "\e90d";
$icon-image: "\e90e";
$icon-images: "\e90f";
$icon-camera: "\e910";
$icon-headphones: "\e911";
$icon-music: "\e912";
$icon-play: "\e913";
$icon-film: "\e914";
$icon-video-camera: "\e915";
$icon-dice: "\e916";
$icon-pacman: "\e917";
$icon-spades: "\e918";
$icon-clubs: "\e919";
$icon-diamonds: "\e91a";
$icon-bullhorn: "\e91b";
$icon-connection: "\e91c";
$icon-podcast: "\e91d";
$icon-feed: "\e91e";
$icon-mic: "\e91f";
$icon-book: "\e920";
$icon-books: "\e921";
$icon-library: "\e922";
$icon-file-text: "\e923";
$icon-profile: "\e924";
$icon-file-empty: "\e925";
$icon-files-empty: "\e926";
$icon-file-text2: "\e927";
$icon-file-picture: "\e928";
$icon-file-music: "\e929";
$icon-file-play: "\e92a";
$icon-file-video: "\e92b";
$icon-file-zip: "\e92c";
$icon-copy: "\e92d";
$icon-paste: "\e92e";
$icon-stack: "\e92f";
$icon-folder: "\e930";
$icon-folder-open: "\e931";
$icon-folder-plus: "\e932";
$icon-folder-minus: "\e933";
$icon-folder-download: "\e934";
$icon-folder-upload: "\e935";
$icon-price-tag: "\e936";
$icon-price-tags: "\e937";
$icon-barcode: "\e938";
$icon-qrcode: "\e939";
$icon-ticket: "\e93a";
$icon-cart: "\e93b";
$icon-coin-dollar: "\e93c";
$icon-coin-euro: "\e93d";
$icon-coin-pound: "\e93e";
$icon-coin-yen: "\e93f";
$icon-credit-card: "\e940";
$icon-calculator: "\e941";
$icon-lifebuoy: "\e942";
$icon-phone: "\e943";
$icon-phone-hang-up: "\e944";
$icon-address-book: "\e945";
$icon-envelop: "\e946";
$icon-pushpin: "\e947";
$icon-location: "\e948";
$icon-location2: "\e949";
$icon-compass: "\e94a";
$icon-compass2: "\e94b";
$icon-map: "\e94c";
$icon-map2: "\e94d";
$icon-history: "\e94e";
$icon-clock: "\e94f";
$icon-clock2: "\e950";
$icon-alarm: "\e951";
$icon-bell: "\e952";
$icon-stopwatch: "\e953";
$icon-calendar: "\e954";
$icon-printer: "\e955";
$icon-keyboard: "\e956";
$icon-display: "\e957";
$icon-laptop: "\e958";
$icon-mobile: "\e959";
$icon-mobile2: "\e95a";
$icon-tablet: "\e95b";
$icon-tv: "\e95c";
$icon-drawer: "\e95d";
$icon-drawer2: "\e95e";
$icon-box-add: "\e95f";
$icon-box-remove: "\e960";
$icon-download: "\e961";
$icon-upload: "\e962";
$icon-floppy-disk: "\e963";
$icon-drive: "\e964";
$icon-database: "\e965";
$icon-undo: "\e966";
$icon-redo: "\e967";
$icon-undo2: "\e968";
$icon-redo2: "\e969";
$icon-forward: "\e96a";
$icon-reply: "\e96b";
$icon-bubble: "\e96c";
$icon-bubbles: "\e96d";
$icon-bubbles2: "\e96e";
$icon-bubble2: "\e96f";
$icon-bubbles3: "\e970";
$icon-bubbles4: "\e971";
$icon-user: "\e972";
$icon-users: "\e973";
$icon-user-plus: "\e974";
$icon-user-minus: "\e975";
$icon-user-check: "\e976";
$icon-user-tie: "\e977";
$icon-quotes-left: "\e978";
$icon-quotes-right: "\e979";
$icon-hour-glass: "\e97a";
$icon-spinner: "\e97b";
$icon-spinner2: "\e97c";
$icon-spinner3: "\e97d";
$icon-spinner4: "\e97e";
$icon-spinner5: "\e97f";
$icon-spinner6: "\e980";
$icon-spinner7: "\e981";
$icon-spinner8: "\e982";
$icon-spinner9: "\e983";
$icon-spinner10: "\e984";
$icon-spinner11: "\e985";
$icon-binoculars: "\e986";
$icon-search: "\e987";
$icon-zoom-in: "\e988";
$icon-zoom-out: "\e989";
$icon-enlarge: "\e98a";
$icon-shrink: "\e98b";
$icon-enlarge2: "\e98c";
$icon-shrink2: "\e98d";
$icon-key: "\e98e";
$icon-key2: "\e98f";
$icon-lock: "\e990";
$icon-unlocked: "\e991";
$icon-wrench: "\e992";
$icon-equalizer: "\e993";
$icon-equalizer2: "\e994";
$icon-cog: "\e995";
$icon-cogs: "\e996";
$icon-hammer: "\e997";
$icon-magic-wand: "\e998";
$icon-aid-kit: "\e999";
$icon-bug: "\e99a";
$icon-pie-chart: "\e99b";
$icon-stats-dots: "\e99c";
$icon-stats-bars: "\e99d";
$icon-stats-bars2: "\e99e";
$icon-trophy: "\e99f";
$icon-gift: "\e9a0";
$icon-glass: "\e9a1";
$icon-glass2: "\e9a2";
$icon-mug: "\e9a3";
$icon-spoon-knife: "\e9a4";
$icon-leaf: "\e9a5";
$icon-rocket: "\e9a6";
$icon-meter: "\e9a7";
$icon-meter2: "\e9a8";
$icon-hammer2: "\e9a9";
$icon-fire: "\e9aa";
$icon-lab: "\e9ab";
$icon-magnet: "\e9ac";
$icon-bin: "\e9ad";
$icon-bin2: "\e9ae";
$icon-briefcase: "\e9af";
$icon-airplane: "\e9b0";
$icon-truck: "\e9b1";
$icon-road: "\e9b2";
$icon-accessibility: "\e9b3";
$icon-target: "\e9b4";
$icon-shield: "\e9b5";
$icon-power: "\e9b6";
$icon-switch: "\e9b7";
$icon-power-cord: "\e9b8";
$icon-clipboard: "\e9b9";
$icon-list-numbered: "\e9ba";
$icon-list: "\e9bb";
$icon-list2: "\e9bc";
$icon-tree: "\e9bd";
$icon-menu: "\e9be";
$icon-menu2: "\e9bf";
$icon-menu3: "\e9c0";
$icon-menu4: "\e9c1";
$icon-cloud: "\e9c2";
$icon-cloud-download: "\e9c3";
$icon-cloud-upload: "\e9c4";
$icon-cloud-check: "\e9c5";
$icon-download2: "\e9c6";
$icon-upload2: "\e9c7";
$icon-download3: "\e9c8";
$icon-upload3: "\e9c9";
$icon-sphere: "\e9ca";
$icon-earth: "\e9cb";
$icon-link: "\e9cc";
$icon-flag: "\e9cd";
$icon-attachment: "\e9ce";
$icon-eye: "\e9cf";
$icon-eye-plus: "\e9d0";
$icon-eye-minus: "\e9d1";
$icon-eye-blocked: "\e9d2";
$icon-bookmark: "\e9d3";
$icon-bookmarks: "\e9d4";
$icon-sun: "\e9d5";
$icon-contrast: "\e9d6";
$icon-brightness-contrast: "\e9d7";
$icon-star-empty: "\e9d8";
$icon-star-half: "\e9d9";
$icon-star-full: "\e9da";
$icon-heart: "\e9db";
$icon-heart-broken: "\e9dc";
$icon-man: "\e9dd";
$icon-woman: "\e9de";
$icon-man-woman: "\e9df";
$icon-happy: "\e9e0";
$icon-happy2: "\e9e1";
$icon-smile: "\e9e2";
$icon-smile2: "\e9e3";
$icon-tongue: "\e9e4";
$icon-tongue2: "\e9e5";
$icon-sad: "\e9e6";
$icon-sad2: "\e9e7";
$icon-wink: "\e9e8";
$icon-wink2: "\e9e9";
$icon-grin: "\e9ea";
$icon-grin2: "\e9eb";
$icon-cool: "\e9ec";
$icon-cool2: "\e9ed";
$icon-angry: "\e9ee";
$icon-angry2: "\e9ef";
$icon-evil: "\e9f0";
$icon-evil2: "\e9f1";
$icon-shocked: "\e9f2";
$icon-shocked2: "\e9f3";
$icon-baffled: "\e9f4";
$icon-baffled2: "\e9f5";
$icon-confused: "\e9f6";
$icon-confused2: "\e9f7";
$icon-neutral: "\e9f8";
$icon-neutral2: "\e9f9";
$icon-hipster: "\e9fa";
$icon-hipster2: "\e9fb";
$icon-wondering: "\e9fc";
$icon-wondering2: "\e9fd";
$icon-sleepy: "\e9fe";
$icon-sleepy2: "\e9ff";
$icon-frustrated: "\ea00";
$icon-frustrated2: "\ea01";
$icon-crying: "\ea02";
$icon-crying2: "\ea03";
$icon-point-up: "\ea04";
$icon-point-right: "\ea05";
$icon-point-down: "\ea06";
$icon-point-left: "\ea07";
$icon-warning: "\ea08";
$icon-notification: "\ea09";
$icon-question: "\ea0a";
$icon-plus: "\ea0b";
$icon-minus: "\ea0c";
$icon-info: "\ea0d";
$icon-cancel-circle: "\ea0e";
$icon-blocked: "\ea0f";
$icon-cross: "\ea10";
$icon-checkmark: "\ea11";
$icon-checkmark2: "\ea12";
$icon-spell-check: "\ea13";
$icon-enter: "\ea14";
$icon-exit: "\ea15";
$icon-play2: "\ea16";
$icon-pause: "\ea17";
$icon-stop: "\ea18";
$icon-previous: "\ea19";
$icon-next: "\ea1a";
$icon-backward: "\ea1b";
$icon-forward2: "\ea1c";
$icon-play3: "\ea1d";
$icon-pause2: "\ea1e";
$icon-stop2: "\ea1f";
$icon-backward2: "\ea20";
$icon-forward3: "\ea21";
$icon-first: "\ea22";
$icon-last: "\ea23";
$icon-previous2: "\ea24";
$icon-next2: "\ea25";
$icon-eject: "\ea26";
$icon-volume-high: "\ea27";
$icon-volume-medium: "\ea28";
$icon-volume-low: "\ea29";
$icon-volume-mute: "\ea2a";
$icon-volume-mute2: "\ea2b";
$icon-volume-increase: "\ea2c";
$icon-volume-decrease: "\ea2d";
$icon-loop: "\ea2e";
$icon-loop2: "\ea2f";
$icon-infinite: "\ea30";
$icon-shuffle: "\ea31";
$icon-arrow-up-left: "\ea32";
$icon-arrow-up: "\ea33";
$icon-arrow-up-right: "\ea34";
$icon-arrow-right: "\ea35";
$icon-arrow-down-right: "\ea36";
$icon-arrow-down: "\ea37";
$icon-arrow-down-left: "\ea38";
$icon-arrow-left: "\ea39";
$icon-arrow-up-left2: "\ea3a";
$icon-arrow-up2: "\ea3b";
$icon-arrow-up-right2: "\ea3c";
$icon-arrow-right2: "\ea3d";
$icon-arrow-down-right2: "\ea3e";
$icon-arrow-down2: "\ea3f";
$icon-arrow-down-left2: "\ea40";
$icon-arrow-left2: "\ea41";
$icon-circle-up: "\ea42";
$icon-circle-right: "\ea43";
$icon-circle-down: "\ea44";
$icon-circle-left: "\ea45";
$icon-tab: "\ea46";
$icon-move-up: "\ea47";
$icon-move-down: "\ea48";
$icon-sort-alpha-asc: "\ea49";
$icon-sort-alpha-desc: "\ea4a";
$icon-sort-numeric-asc: "\ea4b";
$icon-sort-numberic-desc: "\ea4c";
$icon-sort-amount-asc: "\ea4d";
$icon-sort-amount-desc: "\ea4e";
$icon-command: "\ea4f";
$icon-shift: "\ea50";
$icon-ctrl: "\ea51";
$icon-opt: "\ea52";
$icon-checkbox-checked: "\ea53";
$icon-checkbox-unchecked: "\ea54";
$icon-radio-checked: "\ea55";
$icon-radio-checked2: "\ea56";
$icon-radio-unchecked: "\ea57";
$icon-crop: "\ea58";
$icon-make-group: "\ea59";
$icon-ungroup: "\ea5a";
$icon-scissors: "\ea5b";
$icon-filter: "\ea5c";
$icon-font: "\ea5d";
$icon-ligature: "\ea5e";
$icon-ligature2: "\ea5f";
$icon-text-height: "\ea60";
$icon-text-width: "\ea61";
$icon-font-size: "\ea62";
$icon-bold: "\ea63";
$icon-underline: "\ea64";
$icon-italic: "\ea65";
$icon-strikethrough: "\ea66";
$icon-omega: "\ea67";
$icon-sigma: "\ea68";
$icon-page-break: "\ea69";
$icon-superscript: "\ea6a";
$icon-subscript: "\ea6b";
$icon-superscript2: "\ea6c";
$icon-subscript2: "\ea6d";
$icon-text-color: "\ea6e";
$icon-pagebreak: "\ea6f";
$icon-clear-formatting: "\ea70";
$icon-table: "\ea71";
$icon-table2: "\ea72";
$icon-insert-template: "\ea73";
$icon-pilcrow: "\ea74";
$icon-ltr: "\ea75";
$icon-rtl: "\ea76";
$icon-section: "\ea77";
$icon-paragraph-left: "\ea78";
$icon-paragraph-center: "\ea79";
$icon-paragraph-right: "\ea7a";
$icon-paragraph-justify: "\ea7b";
$icon-indent-increase: "\ea7c";
$icon-indent-decrease: "\ea7d";
$icon-share: "\ea7e";
$icon-new-tab: "\ea7f";
$icon-embed: "\ea80";
$icon-embed2: "\ea81";
$icon-terminal: "\ea82";
$icon-share2: "\ea83";
$icon-mail: "\ea84";
$icon-mail2: "\ea85";
$icon-mail3: "\ea86";
$icon-mail4: "\ea87";
$icon-amazon: "\ea88";
$icon-google: "\ea89";
$icon-google2: "\ea8a";
$icon-google3: "\ea8b";
$icon-google-plus: "\ea8c";
$icon-google-plus2: "\ea8d";
$icon-google-plus3: "\ea8e";
$icon-hangouts: "\ea8f";
$icon-google-drive: "\ea90";
$icon-facebook: "\ea91";
$icon-facebook2: "\ea92";
$icon-instagram: "\ea93";
$icon-whatsapp: "\ea94";
$icon-spotify: "\ea95";
$icon-telegram: "\ea96";
$icon-twitter: "\ea97";
$icon-vine: "\ea98";
$icon-vk: "\ea99";
$icon-renren: "\ea9a";
$icon-sina-weibo: "\ea9b";
$icon-rss: "\ea9c";
$icon-rss2: "\ea9d";
$icon-youtube: "\ea9e";
$icon-youtube2: "\ea9f";
$icon-twitch: "\eaa0";
$icon-vimeo: "\eaa1";
$icon-vimeo2: "\eaa2";
$icon-lanyrd: "\eaa3";
$icon-flickr: "\eaa4";
$icon-flickr2: "\eaa5";
$icon-flickr3: "\eaa6";
$icon-flickr4: "\eaa7";
$icon-dribbble: "\eaa8";
$icon-behance: "\eaa9";
$icon-behance2: "\eaaa";
$icon-deviantart: "\eaab";
$icon-500px: "\eaac";
$icon-steam: "\eaad";
$icon-steam2: "\eaae";
$icon-dropbox: "\eaaf";
$icon-onedrive: "\eab0";
$icon-github: "\eab1";
$icon-npm: "\eab2";
$icon-basecamp: "\eab3";
$icon-trello: "\eab4";
$icon-wordpress: "\eab5";
$icon-joomla: "\eab6";
$icon-ello: "\eab7";
$icon-blogger: "\eab8";
$icon-blogger2: "\eab9";
$icon-tumblr: "\eaba";
$icon-tumblr2: "\eabb";
$icon-yahoo: "\eabc";
$icon-yahoo2: "\eabd";
$icon-tux: "\eabe";
$icon-appleinc: "\eabf";
$icon-finder: "\eac0";
$icon-android: "\eac1";
$icon-windows: "\eac2";
$icon-windows8: "\eac3";
$icon-soundcloud: "\eac4";
$icon-soundcloud2: "\eac5";
$icon-skype: "\eac6";
$icon-reddit: "\eac7";
$icon-hackernews: "\eac8";
$icon-wikipedia: "\eac9";
$icon-linkedin: "\eaca";
$icon-linkedin2: "\eacb";
$icon-lastfm: "\eacc";
$icon-lastfm2: "\eacd";
$icon-delicious: "\eace";
$icon-stumbleupon: "\eacf";
$icon-stumbleupon2: "\ead0";
$icon-stackoverflow: "\ead1";
$icon-pinterest: "\ead2";
$icon-pinterest2: "\ead3";
$icon-xing: "\ead4";
$icon-xing2: "\ead5";
$icon-flattr: "\ead6";
$icon-foursquare: "\ead7";
$icon-yelp: "\ead8";
$icon-paypal: "\ead9";
$icon-chrome: "\eada";
$icon-firefox: "\eadb";
$icon-IE: "\eadc";
$icon-edge: "\eadd";
$icon-safari: "\eade";
$icon-opera: "\eadf";
$icon-file-pdf: "\eae0";
$icon-file-openoffice: "\eae1";
$icon-file-word: "\eae2";
$icon-file-excel: "\eae3";
$icon-libreoffice: "\eae4";
$icon-html-five: "\eae5";
$icon-html-five2: "\eae6";
$icon-css3: "\eae7";
$icon-git: "\eae8";
$icon-codepen: "\eae9";
$icon-svg: "\eaea";
$icon-IcoMoon: "\eaeb";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?1onjdh');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?1onjdh#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?1onjdh') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?1onjdh') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?1onjdh##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-f-m-creation {
  &:before {
    content: $icon-f-m-creation; 
  }
}
.icon-f-animateur {
  &:before {
    content: $icon-f-animateur; 
  }
}
.icon-o-clubs {
  &:before {
    content: $icon-o-clubs; 
  }
}
.icon-f-clubs {
  &:before {
    content: $icon-f-clubs; 
  }
}
.icon-type {
  &:before {
    content: $icon-type; 
  }
}
.icon-information {
  &:before {
    content: $icon-information; 
  }
}
.icon-f-animateur1 {
  &:before {
    content: $icon-f-animateur1; 
  }
}
.icon-o-clubs1 {
  &:before {
    content: $icon-o-clubs1; 
  }
}
.icon-f-clubs1 {
  &:before {
    content: $icon-f-clubs1; 
  }
}
.icon-o-mention {
  &:before {
    content: $icon-o-mention; 
  }
}
.icon-type1 {
  &:before {
    content: $icon-type1; 
  }
}
.icon-f-admin {
  &:before {
    content: $icon-f-admin; 
  }
}
.icon-f-bell {
  &:before {
    content: $icon-f-bell; 
  }
}
.icon-f-expand {
  &:before {
    content: $icon-f-expand; 
  }
}
.icon-f-globe {
  &:before {
    content: $icon-f-globe; 
  }
}
.icon-f-arrow {
  &:before {
    content: $icon-f-arrow; 
  }
}
.icon-f-like {
  &:before {
    content: $icon-f-like; 
  }
}
.icon-o-smile {
  &:before {
    content: $icon-o-smile; 
  }
}
.icon-f-open {
  &:before {
    content: $icon-f-open; 
  }
}
.icon-f-settings {
  &:before {
    content: $icon-f-settings; 
  }
}
.icon-f-lock {
  &:before {
    content: $icon-f-lock; 
  }
}
.icon-f-mask {
  &:before {
    content: $icon-f-mask; 
  }
}
.icon-f-chat {
  &:before {
    content: $icon-f-chat; 
  }
}
.icon-f-compass {
  &:before {
    content: $icon-f-compass; 
  }
}
.icon-f-link {
  &:before {
    content: $icon-f-link; 
  }
}
.icon-f-trash {
  &:before {
    content: $icon-f-trash; 
  }
}
.icon-f-flag {
  &:before {
    content: $icon-f-flag; 
  }
}
.icon-f-members {
  &:before {
    content: $icon-f-members; 
  }
}
.icon-f-label {
  &:before {
    content: $icon-f-label; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-cetim {
  &:before {
    content: $icon-cetim; 
  }
}
.icon-f-edit {
  &:before {
    content: $icon-f-edit; 
  }
}
.icon-f-admin1 {
  &:before {
    content: $icon-f-admin1; 
  }
}
.icon-f-bell1 {
  &:before {
    content: $icon-f-bell1; 
  }
}
.icon-f-expand1 {
  &:before {
    content: $icon-f-expand1; 
  }
}
.icon-f-globe1 {
  &:before {
    content: $icon-f-globe1; 
  }
}
.icon-f-arrow1 {
  &:before {
    content: $icon-f-arrow1; 
  }
}
.icon-f-like1 {
  &:before {
    content: $icon-f-like1; 
  }
}
.icon-o-smile1 {
  &:before {
    content: $icon-o-smile1; 
  }
}
.icon-f-open1 {
  &:before {
    content: $icon-f-open1; 
  }
}
.icon-f-settings1 {
  &:before {
    content: $icon-f-settings1; 
  }
}
.icon-f-lock1 {
  &:before {
    content: $icon-f-lock1; 
  }
}
.icon-f-mask1 {
  &:before {
    content: $icon-f-mask1; 
  }
}
.icon-f-chat1 {
  &:before {
    content: $icon-f-chat1; 
  }
}
.icon-f-compass1 {
  &:before {
    content: $icon-f-compass1; 
  }
}
.icon-f-link1 {
  &:before {
    content: $icon-f-link1; 
  }
}
.icon-f-trash1 {
  &:before {
    content: $icon-f-trash1; 
  }
}
.icon-f-flag1 {
  &:before {
    content: $icon-f-flag1; 
  }
}
.icon-f-members1 {
  &:before {
    content: $icon-f-members1; 
  }
}
.icon-f-label1 {
  &:before {
    content: $icon-f-label1; 
  }
}
.icon-company-info {
  &:before {
    content: $icon-company-info; 
  }
}
.icon-role-wizard {
  &:before {
    content: $icon-role-wizard; 
  }
}
.icon-role-manager {
  &:before {
    content: $icon-role-manager; 
  }
}
.icon-contact-all {
  &:before {
    content: $icon-contact-all; 
  }
}
.icon-burger {
  &:before {
    content: $icon-burger; 
  }
}
.icon-catalogue {
  &:before {
    content: $icon-catalogue; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-collaboration {
  &:before {
    content: $icon-collaboration; 
  }
}
.icon-comments {
  &:before {
    content: $icon-comments; 
  }
}
.icon-community {
  &:before {
    content: $icon-community; 
  }
}
.icon-contact {
  &:before {
    content: $icon-contact; 
  }
}
.icon-copy_file {
  &:before {
    content: $icon-copy_file; 
  }
}
.icon-down {
  &:before {
    content: $icon-down; 
  }
}
.icon-download1 {
  &:before {
    content: $icon-download1; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-filter1 {
  &:before {
    content: $icon-filter1; 
  }
}
.icon-fonction {
  &:before {
    content: $icon-fonction; 
  }
}
.icon-home1 {
  &:before {
    content: $icon-home1; 
  }
}
.icon-idea {
  &:before {
    content: $icon-idea; 
  }
}
.icon-info1 {
  &:before {
    content: $icon-info1; 
  }
}
.icon-lab1 {
  &:before {
    content: $icon-lab1; 
  }
}
.icon-edit1 {
  &:before {
    content: $icon-edit1; 
  }
}
.icon-lock1 {
  &:before {
    content: $icon-lock1; 
  }
}
.icon-marker {
  &:before {
    content: $icon-marker; 
  }
}
.icon-mobile1 {
  &:before {
    content: $icon-mobile1; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf; 
  }
}
.icon-picture {
  &:before {
    content: $icon-picture; 
  }
}
.icon-o-gif {
  &:before {
    content: $icon-o-gif; 
  }
}
.icon-preview {
  &:before {
    content: $icon-preview; 
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-shield1 {
  &:before {
    content: $icon-shield1; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-telephone {
  &:before {
    content: $icon-telephone; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-up {
  &:before {
    content: $icon-up; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-world {
  &:before {
    content: $icon-world; 
  }
}
.icon-cetim1 {
  &:before {
    content: $icon-cetim1; 
  }
}
.icon-f-edit1 {
  &:before {
    content: $icon-f-edit1; 
  }
}
.icon-company-info1 {
  &:before {
    content: $icon-company-info1; 
  }
}
.icon-role-wizard1 {
  &:before {
    content: $icon-role-wizard1; 
  }
}
.icon-role-manager1 {
  &:before {
    content: $icon-role-manager1; 
  }
}
.icon-contact-all1 {
  &:before {
    content: $icon-contact-all1; 
  }
}
.icon-burger1 {
  &:before {
    content: $icon-burger1; 
  }
}
.icon-catalogue1 {
  &:before {
    content: $icon-catalogue1; 
  }
}
.icon-close1 {
  &:before {
    content: $icon-close1; 
  }
}
.icon-collaboration1 {
  &:before {
    content: $icon-collaboration1; 
  }
}
.icon-comments1 {
  &:before {
    content: $icon-comments1; 
  }
}
.icon-community1 {
  &:before {
    content: $icon-community1; 
  }
}
.icon-date {
  &:before {
    content: $icon-date; 
  }
}
.icon-arrow-left1 {
  &:before {
    content: $icon-arrow-left1; 
  }
}
.icon-arrow-right1 {
  &:before {
    content: $icon-arrow-right1; 
  }
}
.icon-plus1 {
  &:before {
    content: $icon-plus1; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-publish {
  &:before {
    content: $icon-publish; 
  }
}
.icon-my-projects {
  &:before {
    content: $icon-my-projects; 
  }
}
.icon-annuaire {
  &:before {
    content: $icon-annuaire; 
  }
}
.icon-annuaire-filled {
  &:before {
    content: $icon-annuaire-filled; 
  }
}
.icon-wallet-filled {
  &:before {
    content: $icon-wallet-filled; 
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet; 
  }
}
.icon-collaboration-filled {
  &:before {
    content: $icon-collaboration-filled; 
  }
}
.icon-home-filled {
  &:before {
    content: $icon-home-filled; 
  }
}
.icon-idea-filled {
  &:before {
    content: $icon-idea-filled; 
  }
}
.icon-lab-filled {
  &:before {
    content: $icon-lab-filled; 
  }
}
.icon-organization {
  &:before {
    content: $icon-organization; 
  }
}
.icon-join {
  &:before {
    content: $icon-join; 
  }
}
.icon-see-participants {
  &:before {
    content: $icon-see-participants; 
  }
}
.icon-hierarchy {
  &:before {
    content: $icon-hierarchy; 
  }
}
.icon-employees {
  &:before {
    content: $icon-employees; 
  }
}
.icon-activity {
  &:before {
    content: $icon-activity; 
  }
}
.icon-website {
  &:before {
    content: $icon-website; 
  }
}
.icon-contact1 {
  &:before {
    content: $icon-contact1; 
  }
}
.icon-copy_file1 {
  &:before {
    content: $icon-copy_file1; 
  }
}
.icon-down1 {
  &:before {
    content: $icon-down1; 
  }
}
.icon-download4 {
  &:before {
    content: $icon-download4; 
  }
}
.icon-file1 {
  &:before {
    content: $icon-file1; 
  }
}
.icon-filter2 {
  &:before {
    content: $icon-filter2; 
  }
}
.icon-fonction1 {
  &:before {
    content: $icon-fonction1; 
  }
}
.icon-home4 {
  &:before {
    content: $icon-home4; 
  }
}
.icon-idea1 {
  &:before {
    content: $icon-idea1; 
  }
}
.icon-info2 {
  &:before {
    content: $icon-info2; 
  }
}
.icon-lab2 {
  &:before {
    content: $icon-lab2; 
  }
}
.icon-lock2 {
  &:before {
    content: $icon-lock2; 
  }
}
.icon-marker1 {
  &:before {
    content: $icon-marker1; 
  }
}
.icon-mobile3 {
  &:before {
    content: $icon-mobile3; 
  }
}
.icon-more1 {
  &:before {
    content: $icon-more1; 
  }
}
.icon-pdf1 {
  &:before {
    content: $icon-pdf1; 
  }
}
.icon-picture1 {
  &:before {
    content: $icon-picture1; 
  }
}
.icon-o-gif1 {
  &:before {
    content: $icon-o-gif1; 
  }
}
.icon-preview1 {
  &:before {
    content: $icon-preview1; 
  }
}
.icon-search2 {
  &:before {
    content: $icon-search2; 
  }
}
.icon-settings1 {
  &:before {
    content: $icon-settings1; 
  }
}
.icon-shield2 {
  &:before {
    content: $icon-shield2; 
  }
}
.icon-sort1 {
  &:before {
    content: $icon-sort1; 
  }
}
.icon-star1 {
  &:before {
    content: $icon-star1; 
  }
}
.icon-telephone1 {
  &:before {
    content: $icon-telephone1; 
  }
}
.icon-trash1 {
  &:before {
    content: $icon-trash1; 
  }
}
.icon-up1 {
  &:before {
    content: $icon-up1; 
  }
}
.icon-video1 {
  &:before {
    content: $icon-video1; 
  }
}
.icon-world1 {
  &:before {
    content: $icon-world1; 
  }
}
.icon-date1 {
  &:before {
    content: $icon-date1; 
  }
}
.icon-arrow-left3 {
  &:before {
    content: $icon-arrow-left3; 
  }
}
.icon-arrow-right3 {
  &:before {
    content: $icon-arrow-right3; 
  }
}
.icon-plus2 {
  &:before {
    content: $icon-plus2; 
  }
}
.icon-save1 {
  &:before {
    content: $icon-save1; 
  }
}
.icon-check1 {
  &:before {
    content: $icon-check1; 
  }
}
.icon-publish1 {
  &:before {
    content: $icon-publish1; 
  }
}
.icon-my-projects1 {
  &:before {
    content: $icon-my-projects1; 
  }
}
.icon-annuaire1 {
  &:before {
    content: $icon-annuaire1; 
  }
}
.icon-annuaire-filled1 {
  &:before {
    content: $icon-annuaire-filled1; 
  }
}
.icon-wallet-filled1 {
  &:before {
    content: $icon-wallet-filled1; 
  }
}
.icon-wallet1 {
  &:before {
    content: $icon-wallet1; 
  }
}
.icon-collaboration-filled1 {
  &:before {
    content: $icon-collaboration-filled1; 
  }
}
.icon-home-filled1 {
  &:before {
    content: $icon-home-filled1; 
  }
}
.icon-idea-filled1 {
  &:before {
    content: $icon-idea-filled1; 
  }
}
.icon-lab-filled1 {
  &:before {
    content: $icon-lab-filled1; 
  }
}
.icon-organization1 {
  &:before {
    content: $icon-organization1; 
  }
}
.icon-join1 {
  &:before {
    content: $icon-join1; 
  }
}
.icon-see-participants1 {
  &:before {
    content: $icon-see-participants1; 
  }
}
.icon-hierarchy1 {
  &:before {
    content: $icon-hierarchy1; 
  }
}
.icon-employees1 {
  &:before {
    content: $icon-employees1; 
  }
}
.icon-activity1 {
  &:before {
    content: $icon-activity1; 
  }
}
.icon-website1 {
  &:before {
    content: $icon-website1; 
  }
}
.icon-repost {
  &:before {
    content: $icon-repost; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-home2 {
  &:before {
    content: $icon-home2; 
  }
}
.icon-home3 {
  &:before {
    content: $icon-home3; 
  }
}
.icon-office {
  &:before {
    content: $icon-office; 
  }
}
.icon-newspaper {
  &:before {
    content: $icon-newspaper; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-pencil2 {
  &:before {
    content: $icon-pencil2; 
  }
}
.icon-quill {
  &:before {
    content: $icon-quill; 
  }
}
.icon-pen {
  &:before {
    content: $icon-pen; 
  }
}
.icon-blog {
  &:before {
    content: $icon-blog; 
  }
}
.icon-eyedropper {
  &:before {
    content: $icon-eyedropper; 
  }
}
.icon-droplet {
  &:before {
    content: $icon-droplet; 
  }
}
.icon-paint-format {
  &:before {
    content: $icon-paint-format; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-images {
  &:before {
    content: $icon-images; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-headphones {
  &:before {
    content: $icon-headphones; 
  }
}
.icon-music {
  &:before {
    content: $icon-music; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-film {
  &:before {
    content: $icon-film; 
  }
}
.icon-video-camera {
  &:before {
    content: $icon-video-camera; 
  }
}
.icon-dice {
  &:before {
    content: $icon-dice; 
  }
}
.icon-pacman {
  &:before {
    content: $icon-pacman; 
  }
}
.icon-spades {
  &:before {
    content: $icon-spades; 
  }
}
.icon-clubs {
  &:before {
    content: $icon-clubs; 
  }
}
.icon-diamonds {
  &:before {
    content: $icon-diamonds; 
  }
}
.icon-bullhorn {
  &:before {
    content: $icon-bullhorn; 
  }
}
.icon-connection {
  &:before {
    content: $icon-connection; 
  }
}
.icon-podcast {
  &:before {
    content: $icon-podcast; 
  }
}
.icon-feed {
  &:before {
    content: $icon-feed; 
  }
}
.icon-mic {
  &:before {
    content: $icon-mic; 
  }
}
.icon-book {
  &:before {
    content: $icon-book; 
  }
}
.icon-books {
  &:before {
    content: $icon-books; 
  }
}
.icon-library {
  &:before {
    content: $icon-library; 
  }
}
.icon-file-text {
  &:before {
    content: $icon-file-text; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-file-empty {
  &:before {
    content: $icon-file-empty; 
  }
}
.icon-files-empty {
  &:before {
    content: $icon-files-empty; 
  }
}
.icon-file-text2 {
  &:before {
    content: $icon-file-text2; 
  }
}
.icon-file-picture {
  &:before {
    content: $icon-file-picture; 
  }
}
.icon-file-music {
  &:before {
    content: $icon-file-music; 
  }
}
.icon-file-play {
  &:before {
    content: $icon-file-play; 
  }
}
.icon-file-video {
  &:before {
    content: $icon-file-video; 
  }
}
.icon-file-zip {
  &:before {
    content: $icon-file-zip; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-paste {
  &:before {
    content: $icon-paste; 
  }
}
.icon-stack {
  &:before {
    content: $icon-stack; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open; 
  }
}
.icon-folder-plus {
  &:before {
    content: $icon-folder-plus; 
  }
}
.icon-folder-minus {
  &:before {
    content: $icon-folder-minus; 
  }
}
.icon-folder-download {
  &:before {
    content: $icon-folder-download; 
  }
}
.icon-folder-upload {
  &:before {
    content: $icon-folder-upload; 
  }
}
.icon-price-tag {
  &:before {
    content: $icon-price-tag; 
  }
}
.icon-price-tags {
  &:before {
    content: $icon-price-tags; 
  }
}
.icon-barcode {
  &:before {
    content: $icon-barcode; 
  }
}
.icon-qrcode {
  &:before {
    content: $icon-qrcode; 
  }
}
.icon-ticket {
  &:before {
    content: $icon-ticket; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-coin-dollar {
  &:before {
    content: $icon-coin-dollar; 
  }
}
.icon-coin-euro {
  &:before {
    content: $icon-coin-euro; 
  }
}
.icon-coin-pound {
  &:before {
    content: $icon-coin-pound; 
  }
}
.icon-coin-yen {
  &:before {
    content: $icon-coin-yen; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator; 
  }
}
.icon-lifebuoy {
  &:before {
    content: $icon-lifebuoy; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-phone-hang-up {
  &:before {
    content: $icon-phone-hang-up; 
  }
}
.icon-address-book {
  &:before {
    content: $icon-address-book; 
  }
}
.icon-envelop {
  &:before {
    content: $icon-envelop; 
  }
}
.icon-pushpin {
  &:before {
    content: $icon-pushpin; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-location2 {
  &:before {
    content: $icon-location2; 
  }
}
.icon-compass {
  &:before {
    content: $icon-compass; 
  }
}
.icon-compass2 {
  &:before {
    content: $icon-compass2; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-map2 {
  &:before {
    content: $icon-map2; 
  }
}
.icon-history {
  &:before {
    content: $icon-history; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-clock2 {
  &:before {
    content: $icon-clock2; 
  }
}
.icon-alarm {
  &:before {
    content: $icon-alarm; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-stopwatch {
  &:before {
    content: $icon-stopwatch; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-printer {
  &:before {
    content: $icon-printer; 
  }
}
.icon-keyboard {
  &:before {
    content: $icon-keyboard; 
  }
}
.icon-display {
  &:before {
    content: $icon-display; 
  }
}
.icon-laptop {
  &:before {
    content: $icon-laptop; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-mobile2 {
  &:before {
    content: $icon-mobile2; 
  }
}
.icon-tablet {
  &:before {
    content: $icon-tablet; 
  }
}
.icon-tv {
  &:before {
    content: $icon-tv; 
  }
}
.icon-drawer {
  &:before {
    content: $icon-drawer; 
  }
}
.icon-drawer2 {
  &:before {
    content: $icon-drawer2; 
  }
}
.icon-box-add {
  &:before {
    content: $icon-box-add; 
  }
}
.icon-box-remove {
  &:before {
    content: $icon-box-remove; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-floppy-disk {
  &:before {
    content: $icon-floppy-disk; 
  }
}
.icon-drive {
  &:before {
    content: $icon-drive; 
  }
}
.icon-database {
  &:before {
    content: $icon-database; 
  }
}
.icon-undo {
  &:before {
    content: $icon-undo; 
  }
}
.icon-redo {
  &:before {
    content: $icon-redo; 
  }
}
.icon-undo2 {
  &:before {
    content: $icon-undo2; 
  }
}
.icon-redo2 {
  &:before {
    content: $icon-redo2; 
  }
}
.icon-forward {
  &:before {
    content: $icon-forward; 
  }
}
.icon-reply {
  &:before {
    content: $icon-reply; 
  }
}
.icon-bubble {
  &:before {
    content: $icon-bubble; 
  }
}
.icon-bubbles {
  &:before {
    content: $icon-bubbles; 
  }
}
.icon-bubbles2 {
  &:before {
    content: $icon-bubbles2; 
  }
}
.icon-bubble2 {
  &:before {
    content: $icon-bubble2; 
  }
}
.icon-bubbles3 {
  &:before {
    content: $icon-bubbles3; 
  }
}
.icon-bubbles4 {
  &:before {
    content: $icon-bubbles4; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-user-plus {
  &:before {
    content: $icon-user-plus; 
  }
}
.icon-user-minus {
  &:before {
    content: $icon-user-minus; 
  }
}
.icon-user-check {
  &:before {
    content: $icon-user-check; 
  }
}
.icon-user-tie {
  &:before {
    content: $icon-user-tie; 
  }
}
.icon-quotes-left {
  &:before {
    content: $icon-quotes-left; 
  }
}
.icon-quotes-right {
  &:before {
    content: $icon-quotes-right; 
  }
}
.icon-hour-glass {
  &:before {
    content: $icon-hour-glass; 
  }
}
.icon-spinner {
  &:before {
    content: $icon-spinner; 
  }
}
.icon-spinner2 {
  &:before {
    content: $icon-spinner2; 
  }
}
.icon-spinner3 {
  &:before {
    content: $icon-spinner3; 
  }
}
.icon-spinner4 {
  &:before {
    content: $icon-spinner4; 
  }
}
.icon-spinner5 {
  &:before {
    content: $icon-spinner5; 
  }
}
.icon-spinner6 {
  &:before {
    content: $icon-spinner6; 
  }
}
.icon-spinner7 {
  &:before {
    content: $icon-spinner7; 
  }
}
.icon-spinner8 {
  &:before {
    content: $icon-spinner8; 
  }
}
.icon-spinner9 {
  &:before {
    content: $icon-spinner9; 
  }
}
.icon-spinner10 {
  &:before {
    content: $icon-spinner10; 
  }
}
.icon-spinner11 {
  &:before {
    content: $icon-spinner11; 
  }
}
.icon-binoculars {
  &:before {
    content: $icon-binoculars; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in; 
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out; 
  }
}
.icon-enlarge {
  &:before {
    content: $icon-enlarge; 
  }
}
.icon-shrink {
  &:before {
    content: $icon-shrink; 
  }
}
.icon-enlarge2 {
  &:before {
    content: $icon-enlarge2; 
  }
}
.icon-shrink2 {
  &:before {
    content: $icon-shrink2; 
  }
}
.icon-key {
  &:before {
    content: $icon-key; 
  }
}
.icon-key2 {
  &:before {
    content: $icon-key2; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-unlocked {
  &:before {
    content: $icon-unlocked; 
  }
}
.icon-wrench {
  &:before {
    content: $icon-wrench; 
  }
}
.icon-equalizer {
  &:before {
    content: $icon-equalizer; 
  }
}
.icon-equalizer2 {
  &:before {
    content: $icon-equalizer2; 
  }
}
.icon-cog {
  &:before {
    content: $icon-cog; 
  }
}
.icon-cogs {
  &:before {
    content: $icon-cogs; 
  }
}
.icon-hammer {
  &:before {
    content: $icon-hammer; 
  }
}
.icon-magic-wand {
  &:before {
    content: $icon-magic-wand; 
  }
}
.icon-aid-kit {
  &:before {
    content: $icon-aid-kit; 
  }
}
.icon-bug {
  &:before {
    content: $icon-bug; 
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; 
  }
}
.icon-stats-dots {
  &:before {
    content: $icon-stats-dots; 
  }
}
.icon-stats-bars {
  &:before {
    content: $icon-stats-bars; 
  }
}
.icon-stats-bars2 {
  &:before {
    content: $icon-stats-bars2; 
  }
}
.icon-trophy {
  &:before {
    content: $icon-trophy; 
  }
}
.icon-gift {
  &:before {
    content: $icon-gift; 
  }
}
.icon-glass {
  &:before {
    content: $icon-glass; 
  }
}
.icon-glass2 {
  &:before {
    content: $icon-glass2; 
  }
}
.icon-mug {
  &:before {
    content: $icon-mug; 
  }
}
.icon-spoon-knife {
  &:before {
    content: $icon-spoon-knife; 
  }
}
.icon-leaf {
  &:before {
    content: $icon-leaf; 
  }
}
.icon-rocket {
  &:before {
    content: $icon-rocket; 
  }
}
.icon-meter {
  &:before {
    content: $icon-meter; 
  }
}
.icon-meter2 {
  &:before {
    content: $icon-meter2; 
  }
}
.icon-hammer2 {
  &:before {
    content: $icon-hammer2; 
  }
}
.icon-fire {
  &:before {
    content: $icon-fire; 
  }
}
.icon-lab {
  &:before {
    content: $icon-lab; 
  }
}
.icon-magnet {
  &:before {
    content: $icon-magnet; 
  }
}
.icon-bin {
  &:before {
    content: $icon-bin; 
  }
}
.icon-bin2 {
  &:before {
    content: $icon-bin2; 
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase; 
  }
}
.icon-airplane {
  &:before {
    content: $icon-airplane; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-road {
  &:before {
    content: $icon-road; 
  }
}
.icon-accessibility {
  &:before {
    content: $icon-accessibility; 
  }
}
.icon-target {
  &:before {
    content: $icon-target; 
  }
}
.icon-shield {
  &:before {
    content: $icon-shield; 
  }
}
.icon-power {
  &:before {
    content: $icon-power; 
  }
}
.icon-switch {
  &:before {
    content: $icon-switch; 
  }
}
.icon-power-cord {
  &:before {
    content: $icon-power-cord; 
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard; 
  }
}
.icon-list-numbered {
  &:before {
    content: $icon-list-numbered; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-list2 {
  &:before {
    content: $icon-list2; 
  }
}
.icon-tree {
  &:before {
    content: $icon-tree; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-menu2 {
  &:before {
    content: $icon-menu2; 
  }
}
.icon-menu3 {
  &:before {
    content: $icon-menu3; 
  }
}
.icon-menu4 {
  &:before {
    content: $icon-menu4; 
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud; 
  }
}
.icon-cloud-download {
  &:before {
    content: $icon-cloud-download; 
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload; 
  }
}
.icon-cloud-check {
  &:before {
    content: $icon-cloud-check; 
  }
}
.icon-download2 {
  &:before {
    content: $icon-download2; 
  }
}
.icon-upload2 {
  &:before {
    content: $icon-upload2; 
  }
}
.icon-download3 {
  &:before {
    content: $icon-download3; 
  }
}
.icon-upload3 {
  &:before {
    content: $icon-upload3; 
  }
}
.icon-sphere {
  &:before {
    content: $icon-sphere; 
  }
}
.icon-earth {
  &:before {
    content: $icon-earth; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-eye-plus {
  &:before {
    content: $icon-eye-plus; 
  }
}
.icon-eye-minus {
  &:before {
    content: $icon-eye-minus; 
  }
}
.icon-eye-blocked {
  &:before {
    content: $icon-eye-blocked; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-bookmarks {
  &:before {
    content: $icon-bookmarks; 
  }
}
.icon-sun {
  &:before {
    content: $icon-sun; 
  }
}
.icon-contrast {
  &:before {
    content: $icon-contrast; 
  }
}
.icon-brightness-contrast {
  &:before {
    content: $icon-brightness-contrast; 
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty; 
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half; 
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-heart-broken {
  &:before {
    content: $icon-heart-broken; 
  }
}
.icon-man {
  &:before {
    content: $icon-man; 
  }
}
.icon-woman {
  &:before {
    content: $icon-woman; 
  }
}
.icon-man-woman {
  &:before {
    content: $icon-man-woman; 
  }
}
.icon-happy {
  &:before {
    content: $icon-happy; 
  }
}
.icon-happy2 {
  &:before {
    content: $icon-happy2; 
  }
}
.icon-smile {
  &:before {
    content: $icon-smile; 
  }
}
.icon-smile2 {
  &:before {
    content: $icon-smile2; 
  }
}
.icon-tongue {
  &:before {
    content: $icon-tongue; 
  }
}
.icon-tongue2 {
  &:before {
    content: $icon-tongue2; 
  }
}
.icon-sad {
  &:before {
    content: $icon-sad; 
  }
}
.icon-sad2 {
  &:before {
    content: $icon-sad2; 
  }
}
.icon-wink {
  &:before {
    content: $icon-wink; 
  }
}
.icon-wink2 {
  &:before {
    content: $icon-wink2; 
  }
}
.icon-grin {
  &:before {
    content: $icon-grin; 
  }
}
.icon-grin2 {
  &:before {
    content: $icon-grin2; 
  }
}
.icon-cool {
  &:before {
    content: $icon-cool; 
  }
}
.icon-cool2 {
  &:before {
    content: $icon-cool2; 
  }
}
.icon-angry {
  &:before {
    content: $icon-angry; 
  }
}
.icon-angry2 {
  &:before {
    content: $icon-angry2; 
  }
}
.icon-evil {
  &:before {
    content: $icon-evil; 
  }
}
.icon-evil2 {
  &:before {
    content: $icon-evil2; 
  }
}
.icon-shocked {
  &:before {
    content: $icon-shocked; 
  }
}
.icon-shocked2 {
  &:before {
    content: $icon-shocked2; 
  }
}
.icon-baffled {
  &:before {
    content: $icon-baffled; 
  }
}
.icon-baffled2 {
  &:before {
    content: $icon-baffled2; 
  }
}
.icon-confused {
  &:before {
    content: $icon-confused; 
  }
}
.icon-confused2 {
  &:before {
    content: $icon-confused2; 
  }
}
.icon-neutral {
  &:before {
    content: $icon-neutral; 
  }
}
.icon-neutral2 {
  &:before {
    content: $icon-neutral2; 
  }
}
.icon-hipster {
  &:before {
    content: $icon-hipster; 
  }
}
.icon-hipster2 {
  &:before {
    content: $icon-hipster2; 
  }
}
.icon-wondering {
  &:before {
    content: $icon-wondering; 
  }
}
.icon-wondering2 {
  &:before {
    content: $icon-wondering2; 
  }
}
.icon-sleepy {
  &:before {
    content: $icon-sleepy; 
  }
}
.icon-sleepy2 {
  &:before {
    content: $icon-sleepy2; 
  }
}
.icon-frustrated {
  &:before {
    content: $icon-frustrated; 
  }
}
.icon-frustrated2 {
  &:before {
    content: $icon-frustrated2; 
  }
}
.icon-crying {
  &:before {
    content: $icon-crying; 
  }
}
.icon-crying2 {
  &:before {
    content: $icon-crying2; 
  }
}
.icon-point-up {
  &:before {
    content: $icon-point-up; 
  }
}
.icon-point-right {
  &:before {
    content: $icon-point-right; 
  }
}
.icon-point-down {
  &:before {
    content: $icon-point-down; 
  }
}
.icon-point-left {
  &:before {
    content: $icon-point-left; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-cancel-circle {
  &:before {
    content: $icon-cancel-circle; 
  }
}
.icon-blocked {
  &:before {
    content: $icon-blocked; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-checkmark2 {
  &:before {
    content: $icon-checkmark2; 
  }
}
.icon-spell-check {
  &:before {
    content: $icon-spell-check; 
  }
}
.icon-enter {
  &:before {
    content: $icon-enter; 
  }
}
.icon-exit {
  &:before {
    content: $icon-exit; 
  }
}
.icon-play2 {
  &:before {
    content: $icon-play2; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-stop {
  &:before {
    content: $icon-stop; 
  }
}
.icon-previous {
  &:before {
    content: $icon-previous; 
  }
}
.icon-next {
  &:before {
    content: $icon-next; 
  }
}
.icon-backward {
  &:before {
    content: $icon-backward; 
  }
}
.icon-forward2 {
  &:before {
    content: $icon-forward2; 
  }
}
.icon-play3 {
  &:before {
    content: $icon-play3; 
  }
}
.icon-pause2 {
  &:before {
    content: $icon-pause2; 
  }
}
.icon-stop2 {
  &:before {
    content: $icon-stop2; 
  }
}
.icon-backward2 {
  &:before {
    content: $icon-backward2; 
  }
}
.icon-forward3 {
  &:before {
    content: $icon-forward3; 
  }
}
.icon-first {
  &:before {
    content: $icon-first; 
  }
}
.icon-last {
  &:before {
    content: $icon-last; 
  }
}
.icon-previous2 {
  &:before {
    content: $icon-previous2; 
  }
}
.icon-next2 {
  &:before {
    content: $icon-next2; 
  }
}
.icon-eject {
  &:before {
    content: $icon-eject; 
  }
}
.icon-volume-high {
  &:before {
    content: $icon-volume-high; 
  }
}
.icon-volume-medium {
  &:before {
    content: $icon-volume-medium; 
  }
}
.icon-volume-low {
  &:before {
    content: $icon-volume-low; 
  }
}
.icon-volume-mute {
  &:before {
    content: $icon-volume-mute; 
  }
}
.icon-volume-mute2 {
  &:before {
    content: $icon-volume-mute2; 
  }
}
.icon-volume-increase {
  &:before {
    content: $icon-volume-increase; 
  }
}
.icon-volume-decrease {
  &:before {
    content: $icon-volume-decrease; 
  }
}
.icon-loop {
  &:before {
    content: $icon-loop; 
  }
}
.icon-loop2 {
  &:before {
    content: $icon-loop2; 
  }
}
.icon-infinite {
  &:before {
    content: $icon-infinite; 
  }
}
.icon-shuffle {
  &:before {
    content: $icon-shuffle; 
  }
}
.icon-arrow-up-left {
  &:before {
    content: $icon-arrow-up-left; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-up-right {
  &:before {
    content: $icon-arrow-up-right; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-down-right {
  &:before {
    content: $icon-arrow-down-right; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-down-left {
  &:before {
    content: $icon-arrow-down-left; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-up-left2 {
  &:before {
    content: $icon-arrow-up-left2; 
  }
}
.icon-arrow-up2 {
  &:before {
    content: $icon-arrow-up2; 
  }
}
.icon-arrow-up-right2 {
  &:before {
    content: $icon-arrow-up-right2; 
  }
}
.icon-arrow-right2 {
  &:before {
    content: $icon-arrow-right2; 
  }
}
.icon-arrow-down-right2 {
  &:before {
    content: $icon-arrow-down-right2; 
  }
}
.icon-arrow-down2 {
  &:before {
    content: $icon-arrow-down2; 
  }
}
.icon-arrow-down-left2 {
  &:before {
    content: $icon-arrow-down-left2; 
  }
}
.icon-arrow-left2 {
  &:before {
    content: $icon-arrow-left2; 
  }
}
.icon-circle-up {
  &:before {
    content: $icon-circle-up; 
  }
}
.icon-circle-right {
  &:before {
    content: $icon-circle-right; 
  }
}
.icon-circle-down {
  &:before {
    content: $icon-circle-down; 
  }
}
.icon-circle-left {
  &:before {
    content: $icon-circle-left; 
  }
}
.icon-tab {
  &:before {
    content: $icon-tab; 
  }
}
.icon-move-up {
  &:before {
    content: $icon-move-up; 
  }
}
.icon-move-down {
  &:before {
    content: $icon-move-down; 
  }
}
.icon-sort-alpha-asc {
  &:before {
    content: $icon-sort-alpha-asc; 
  }
}
.icon-sort-alpha-desc {
  &:before {
    content: $icon-sort-alpha-desc; 
  }
}
.icon-sort-numeric-asc {
  &:before {
    content: $icon-sort-numeric-asc; 
  }
}
.icon-sort-numberic-desc {
  &:before {
    content: $icon-sort-numberic-desc; 
  }
}
.icon-sort-amount-asc {
  &:before {
    content: $icon-sort-amount-asc; 
  }
}
.icon-sort-amount-desc {
  &:before {
    content: $icon-sort-amount-desc; 
  }
}
.icon-command {
  &:before {
    content: $icon-command; 
  }
}
.icon-shift {
  &:before {
    content: $icon-shift; 
  }
}
.icon-ctrl {
  &:before {
    content: $icon-ctrl; 
  }
}
.icon-opt {
  &:before {
    content: $icon-opt; 
  }
}
.icon-checkbox-checked {
  &:before {
    content: $icon-checkbox-checked; 
  }
}
.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked; 
  }
}
.icon-radio-checked {
  &:before {
    content: $icon-radio-checked; 
  }
}
.icon-radio-checked2 {
  &:before {
    content: $icon-radio-checked2; 
  }
}
.icon-radio-unchecked {
  &:before {
    content: $icon-radio-unchecked; 
  }
}
.icon-crop {
  &:before {
    content: $icon-crop; 
  }
}
.icon-make-group {
  &:before {
    content: $icon-make-group; 
  }
}
.icon-ungroup {
  &:before {
    content: $icon-ungroup; 
  }
}
.icon-scissors {
  &:before {
    content: $icon-scissors; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-font {
  &:before {
    content: $icon-font; 
  }
}
.icon-ligature {
  &:before {
    content: $icon-ligature; 
  }
}
.icon-ligature2 {
  &:before {
    content: $icon-ligature2; 
  }
}
.icon-text-height {
  &:before {
    content: $icon-text-height; 
  }
}
.icon-text-width {
  &:before {
    content: $icon-text-width; 
  }
}
.icon-font-size {
  &:before {
    content: $icon-font-size; 
  }
}
.icon-bold {
  &:before {
    content: $icon-bold; 
  }
}
.icon-underline {
  &:before {
    content: $icon-underline; 
  }
}
.icon-italic {
  &:before {
    content: $icon-italic; 
  }
}
.icon-strikethrough {
  &:before {
    content: $icon-strikethrough; 
  }
}
.icon-omega {
  &:before {
    content: $icon-omega; 
  }
}
.icon-sigma {
  &:before {
    content: $icon-sigma; 
  }
}
.icon-page-break {
  &:before {
    content: $icon-page-break; 
  }
}
.icon-superscript {
  &:before {
    content: $icon-superscript; 
  }
}
.icon-subscript {
  &:before {
    content: $icon-subscript; 
  }
}
.icon-superscript2 {
  &:before {
    content: $icon-superscript2; 
  }
}
.icon-subscript2 {
  &:before {
    content: $icon-subscript2; 
  }
}
.icon-text-color {
  &:before {
    content: $icon-text-color; 
  }
}
.icon-pagebreak {
  &:before {
    content: $icon-pagebreak; 
  }
}
.icon-clear-formatting {
  &:before {
    content: $icon-clear-formatting; 
  }
}
.icon-table {
  &:before {
    content: $icon-table; 
  }
}
.icon-table2 {
  &:before {
    content: $icon-table2; 
  }
}
.icon-insert-template {
  &:before {
    content: $icon-insert-template; 
  }
}
.icon-pilcrow {
  &:before {
    content: $icon-pilcrow; 
  }
}
.icon-ltr {
  &:before {
    content: $icon-ltr; 
  }
}
.icon-rtl {
  &:before {
    content: $icon-rtl; 
  }
}
.icon-section {
  &:before {
    content: $icon-section; 
  }
}
.icon-paragraph-left {
  &:before {
    content: $icon-paragraph-left; 
  }
}
.icon-paragraph-center {
  &:before {
    content: $icon-paragraph-center; 
  }
}
.icon-paragraph-right {
  &:before {
    content: $icon-paragraph-right; 
  }
}
.icon-paragraph-justify {
  &:before {
    content: $icon-paragraph-justify; 
  }
}
.icon-indent-increase {
  &:before {
    content: $icon-indent-increase; 
  }
}
.icon-indent-decrease {
  &:before {
    content: $icon-indent-decrease; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-new-tab {
  &:before {
    content: $icon-new-tab; 
  }
}
.icon-embed {
  &:before {
    content: $icon-embed; 
  }
}
.icon-embed2 {
  &:before {
    content: $icon-embed2; 
  }
}
.icon-terminal {
  &:before {
    content: $icon-terminal; 
  }
}
.icon-share2 {
  &:before {
    content: $icon-share2; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-mail2 {
  &:before {
    content: $icon-mail2; 
  }
}
.icon-mail3 {
  &:before {
    content: $icon-mail3; 
  }
}
.icon-mail4 {
  &:before {
    content: $icon-mail4; 
  }
}
.icon-amazon {
  &:before {
    content: $icon-amazon; 
  }
}
.icon-google {
  &:before {
    content: $icon-google; 
  }
}
.icon-google2 {
  &:before {
    content: $icon-google2; 
  }
}
.icon-google3 {
  &:before {
    content: $icon-google3; 
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus; 
  }
}
.icon-google-plus2 {
  &:before {
    content: $icon-google-plus2; 
  }
}
.icon-google-plus3 {
  &:before {
    content: $icon-google-plus3; 
  }
}
.icon-hangouts {
  &:before {
    content: $icon-hangouts; 
  }
}
.icon-google-drive {
  &:before {
    content: $icon-google-drive; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-facebook2 {
  &:before {
    content: $icon-facebook2; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-spotify {
  &:before {
    content: $icon-spotify; 
  }
}
.icon-telegram {
  &:before {
    content: $icon-telegram; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-vine {
  &:before {
    content: $icon-vine; 
  }
}
.icon-vk {
  &:before {
    content: $icon-vk; 
  }
}
.icon-renren {
  &:before {
    content: $icon-renren; 
  }
}
.icon-sina-weibo {
  &:before {
    content: $icon-sina-weibo; 
  }
}
.icon-rss {
  &:before {
    content: $icon-rss; 
  }
}
.icon-rss2 {
  &:before {
    content: $icon-rss2; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-youtube2 {
  &:before {
    content: $icon-youtube2; 
  }
}
.icon-twitch {
  &:before {
    content: $icon-twitch; 
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo; 
  }
}
.icon-vimeo2 {
  &:before {
    content: $icon-vimeo2; 
  }
}
.icon-lanyrd {
  &:before {
    content: $icon-lanyrd; 
  }
}
.icon-flickr {
  &:before {
    content: $icon-flickr; 
  }
}
.icon-flickr2 {
  &:before {
    content: $icon-flickr2; 
  }
}
.icon-flickr3 {
  &:before {
    content: $icon-flickr3; 
  }
}
.icon-flickr4 {
  &:before {
    content: $icon-flickr4; 
  }
}
.icon-dribbble {
  &:before {
    content: $icon-dribbble; 
  }
}
.icon-behance {
  &:before {
    content: $icon-behance; 
  }
}
.icon-behance2 {
  &:before {
    content: $icon-behance2; 
  }
}
.icon-deviantart {
  &:before {
    content: $icon-deviantart; 
  }
}
.icon-500px {
  &:before {
    content: $icon-500px; 
  }
}
.icon-steam {
  &:before {
    content: $icon-steam; 
  }
}
.icon-steam2 {
  &:before {
    content: $icon-steam2; 
  }
}
.icon-dropbox {
  &:before {
    content: $icon-dropbox; 
  }
}
.icon-onedrive {
  &:before {
    content: $icon-onedrive; 
  }
}
.icon-github {
  &:before {
    content: $icon-github; 
  }
}
.icon-npm {
  &:before {
    content: $icon-npm; 
  }
}
.icon-basecamp {
  &:before {
    content: $icon-basecamp; 
  }
}
.icon-trello {
  &:before {
    content: $icon-trello; 
  }
}
.icon-wordpress {
  &:before {
    content: $icon-wordpress; 
  }
}
.icon-joomla {
  &:before {
    content: $icon-joomla; 
  }
}
.icon-ello {
  &:before {
    content: $icon-ello; 
  }
}
.icon-blogger {
  &:before {
    content: $icon-blogger; 
  }
}
.icon-blogger2 {
  &:before {
    content: $icon-blogger2; 
  }
}
.icon-tumblr {
  &:before {
    content: $icon-tumblr; 
  }
}
.icon-tumblr2 {
  &:before {
    content: $icon-tumblr2; 
  }
}
.icon-yahoo {
  &:before {
    content: $icon-yahoo; 
  }
}
.icon-yahoo2 {
  &:before {
    content: $icon-yahoo2; 
  }
}
.icon-tux {
  &:before {
    content: $icon-tux; 
  }
}
.icon-appleinc {
  &:before {
    content: $icon-appleinc; 
  }
}
.icon-finder {
  &:before {
    content: $icon-finder; 
  }
}
.icon-android {
  &:before {
    content: $icon-android; 
  }
}
.icon-windows {
  &:before {
    content: $icon-windows; 
  }
}
.icon-windows8 {
  &:before {
    content: $icon-windows8; 
  }
}
.icon-soundcloud {
  &:before {
    content: $icon-soundcloud; 
  }
}
.icon-soundcloud2 {
  &:before {
    content: $icon-soundcloud2; 
  }
}
.icon-skype {
  &:before {
    content: $icon-skype; 
  }
}
.icon-reddit {
  &:before {
    content: $icon-reddit; 
  }
}
.icon-hackernews {
  &:before {
    content: $icon-hackernews; 
  }
}
.icon-wikipedia {
  &:before {
    content: $icon-wikipedia; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-linkedin2 {
  &:before {
    content: $icon-linkedin2; 
  }
}
.icon-lastfm {
  &:before {
    content: $icon-lastfm; 
  }
}
.icon-lastfm2 {
  &:before {
    content: $icon-lastfm2; 
  }
}
.icon-delicious {
  &:before {
    content: $icon-delicious; 
  }
}
.icon-stumbleupon {
  &:before {
    content: $icon-stumbleupon; 
  }
}
.icon-stumbleupon2 {
  &:before {
    content: $icon-stumbleupon2; 
  }
}
.icon-stackoverflow {
  &:before {
    content: $icon-stackoverflow; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-pinterest2 {
  &:before {
    content: $icon-pinterest2; 
  }
}
.icon-xing {
  &:before {
    content: $icon-xing; 
  }
}
.icon-xing2 {
  &:before {
    content: $icon-xing2; 
  }
}
.icon-flattr {
  &:before {
    content: $icon-flattr; 
  }
}
.icon-foursquare {
  &:before {
    content: $icon-foursquare; 
  }
}
.icon-yelp {
  &:before {
    content: $icon-yelp; 
  }
}
.icon-paypal {
  &:before {
    content: $icon-paypal; 
  }
}
.icon-chrome {
  &:before {
    content: $icon-chrome; 
  }
}
.icon-firefox {
  &:before {
    content: $icon-firefox; 
  }
}
.icon-IE {
  &:before {
    content: $icon-IE; 
  }
}
.icon-edge {
  &:before {
    content: $icon-edge; 
  }
}
.icon-safari {
  &:before {
    content: $icon-safari; 
  }
}
.icon-opera {
  &:before {
    content: $icon-opera; 
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf; 
  }
}
.icon-file-openoffice {
  &:before {
    content: $icon-file-openoffice; 
  }
}
.icon-file-word {
  &:before {
    content: $icon-file-word; 
  }
}
.icon-file-excel {
  &:before {
    content: $icon-file-excel; 
  }
}
.icon-libreoffice {
  &:before {
    content: $icon-libreoffice; 
  }
}
.icon-html-five {
  &:before {
    content: $icon-html-five; 
  }
}
.icon-html-five2 {
  &:before {
    content: $icon-html-five2; 
  }
}
.icon-css3 {
  &:before {
    content: $icon-css3; 
  }
}
.icon-git {
  &:before {
    content: $icon-git; 
  }
}
.icon-codepen {
  &:before {
    content: $icon-codepen; 
  }
}
.icon-svg {
  &:before {
    content: $icon-svg; 
  }
}
.icon-IcoMoon {
  &:before {
    content: $icon-IcoMoon; 
  }
}

