.home-projects {
    border-radius: 0px 0px 10px 10px !important;

    &__heading {
        background: var(--secondary);
        color: var(--white);

        p,
        h4 {
            color: var(--home-project-header);
        }
    }

    &__list-item {
        cursor: pointer;
        &:last-child {
            border: none;
        }
    }
}
