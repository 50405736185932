.wizard {
    margin-top: 1.5rem;

    &__item {
        position: relative;

        &:before {
            content: ' ';
            height: 2px;
            background: var(--wizard-progress-background);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            margin: auto;
            width: 100%;
        }

        &:last-child {
            &:before {
                display: none;
            }
        }
    }

    &__item-pointer {
        cursor: pointer;
    }

    &__item-active {
        .wizard__node {
            color: var(--button1-text-color);
            background-color: var(--primary-color);
        }
    }

    &__item-past,
    &__item-finish {
        .wizard__node {
            color: var(--button1-text-color);
            background: var(--primary-color);
        }
    }

    &__item-past {
        &:before {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4095c8+0,45e4ba+100 */
            background: var(--primary-color); /* Old browsers */
            background: -moz-linear-gradient(left, var(--primary-color) 0%, var(--primary-color) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(
                left,
                var(--primary-color) 0%,
                var(--primary-color) 100%
            ); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                to right,
                var(--primary-color) 0%,
                var(--primary-color) 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--primary-color)',GradientType=1 ); /* IE6-9 */
        }

        .wizard__node {
            color: var(--button1-text-color);
            background: var(--primary-color);
        }
    }

    &__item-finish {
        &:before {
            background: var(--primary-color);
        }
    }

    &__node {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        color: var(--primary-color);
        background-color: var(--button1-text-color);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        &:hover {
            text-decoration: none;
        }

        @media (max-width: 620px) {
            width: 40px;
            height: 40px;
            border-radius: 40px;
        }
    }
}
