.avatar-container {
    position: relative;
    min-width: 60px;
}

.plan-badge {
    max-width: 23px;
    position: absolute;
    bottom: -6px;
    right: -6px;
    filter: drop-shadow(0 0 0.2rem black);
}

.username-container .plan-badge {
    max-width: 23px;
    filter: none !important;
    margin: auto 0px;
    position: relative !important;
}

.username-container {
    display: inline-flex;
    flex-direction: column;
}

.avatar-username-container {
    display: flex;
}
