::-webkit-scrollbar {
    width: 8px;
    height: 15px;
}
::-webkit-scrollbar-button {
    width: 8px;
    height: 15px;
}
::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
    border: 0px none #ffffff;
    border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover);
}

::-webkit-scrollbar-corner {
    background: transparent;
}

//SCROLLBAR STYLING FOR MOZILLA
.scroller {
    scrollbar-color: var(--scrollbar-thumb);
    scrollbar-width: thin;
}
