.list-view {
    width: calc(100% - 275px);
    margin-left: 275px;

    @media (max-width: 991.98px) {
        width: 100%;
        margin-left: 0px;
    }
}

.spinner-border {
    font-size: 1.25rem;
    margin: 50px auto;
}
