@import 'constants/layout';

.subnav {
    padding: 0;
    ul {
        margin-top: 1rem;
    }

    @media (min-width: $breakpointLg) {
        ul {
            margin-top: 0;
        }
    }

    li.nav-item {
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
        a.nav-link {
            color: #a9a9a9;
            font-weight: 600;
            padding: 20px 15px;
            font-size: 1rem;

            &:hover {
                color: var(--primary-color) !important;
                font-weight: bold;
                cursor: pointer;
            }

            @media (max-width: 767.98px) {
                padding: 15px;
            }
        }

        &.active a.nav-link {
            color: var(--primary-color);
            font-weight: bold;
            background-color: var(--white);
            border-radius: 30px;
            box-shadow: 0px 7px 15px rgb(0 0 0 / 10%);
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
        }

        a.nav-link.disabled {
            color: #fff;
            opacity: 0.4;
            cursor: not-allowed;

            @media (max-width: 991.98px) {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
    .subnav {
        text-align: center;
    }
}

.admin-wukoins-before,
.admin-wukoins-after {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}

.admin-wukoins-before p,
.admin-wukoins-after p {
    display: inline-block;
    font-weight: bold;
    border: 1px solid #4095c8;
    border-radius: 25px;
    color: #4095c8;
    width: auto;
    margin: auto;
    padding: 1px 6px;
    min-width: 27px;
}

.admin-wukoins-after p {
    border: 1px solid #30b18e;
    color: #30b18e;
}
