.profileOwner {
    .avatar {
        width: 40px !important;
        height: 40px !important;
        font-size: 0.9rem !important;

        img {
            width: 40px !important;
            height: 40px !important;
        }
    }
}
.list-participants {
    margin: 5px 0 0 0;
    padding: 0;

    > li {
        list-style: none;
        margin-right: 10px;
    }

    .surrounded {
        .avatar {
            border: solid 2px var(--primary-color);
        }
    }

    .avatar,
    .more {
        width: 40px !important;
        height: 40px !important;
        font-size: 0.9rem !important;

        img {
            width: 40px !important;
            height: 40px !important;
        }
    }

    .more {
        border: solid 1px var(--primary);
        border-radius: 40px;
        text-align: center;
        color: var(--primary);
        display: table-cell;
        line-height: 2.1rem;
        cursor: pointer;

        span {
            vertical-align: middle;
            display: inline-block;
        }
    }
}

@media (max-width: 600px) {
    .block-participants {
        margin: 20px 0 0 0;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: solid 1px #ececec;
        border-bottom: solid 1px #ececec;
    }
}
