.url-render {
    border: solid 1px #ccc;
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    display: flex;

    .block-url-image {
        width: 35%;
        min-width: 90px;
        height: auto;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .block-url-infos {
        padding: 15px;
        max-width: calc(100% - 90px);
    }

    @media (max-width: 991.98px) {
        .site-url {
            margin-bottom: 0.5em;
        }
    }

    .site-title {
        font-size: 20px;

        @media (max-width: 991.98px) {
            font-size: 15px;
            line-height: 1.2;
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        color: var(--primary);
        font-weight: 500;
    }
}
