@import 'bootstrap/scss/bootstrap';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
@import 'ngx-toastr/toastr.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import '~@ctrl/ngx-emoji-mart/picker';

@import 'primeicons/primeicons.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.css';

@import 'constants/_fonticon';
@import 'constants/_fonts';
@import 'constants/_layout';

@import './components/_icons';
@import './components/_community-list';
@import './components/_avatar';
@import './components/_profile';
@import './components/_topbar';
@import './components/_tabs';
@import './components/_mainnav';
@import './components/_page-banner';
@import './components/_components-users';
@import './components/_filtres';
@import './components/_subnav';
@import './components/_card';
@import './components/_buttons';
@import './components/_form';
@import './components/_side-folder';
@import './components/_listview';
@import './components/_url-render';
@import './components/_posts';
@import './components/_wallet-history';
@import './components/_login';
@import './components/_modal';
@import './components/_equal-height';
@import './components/_vote';
@import './components/_wizard';
@import './components/_home-projects-list';
@import './components/_participants-projet';
@import './components/_scoop-it';
@import './components/_images';
@import './components/_projects-list';
@import './components/_quill';
@import './components/_project-header';
@import './components/_onboarding';
@import './components/_iframe';
@import './components/_scrollbar';
@import './_shared';

@import './theme-customization/_bootstrap-customization';
@import './theme-customization/_wudo-platform-customization';
@import './theme-customization/_primeng-wudo-theme';
