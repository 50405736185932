.vote {
    &__star {
        display: block;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    &__star-default {
        path {
            fill: none;
            stroke: #000;
            stroke-width: 10px;
        }

        &:hover path {
            fill: #ffc107;
            stroke: none;
        }
    }

    &__star-active {
        path {
            fill: #ffc107;
        }

        &:hover path {
            fill: #ffc107;
            stroke: #000;
            stroke-width: 10px;
        }
    }
}
