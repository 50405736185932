:root {
    --nav-text-color: #8f9bb3;
    --nav-icon-color: #8f9bb3;
    --nav-active-color: var(--primary-color);
}
.navcontainer {
    position: fixed;
    left: 0;
    z-index: 350;
}

.navcontainer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    em {
        margin: 0 20px;
        align-content: center;
    }

    [class^='icon-'] {
        font-size: 30px;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 25px;
        }
    }
}

.mainnav-item {
    width: 100%;
}

.mainnav-item-link {
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
        height: 100%;
    }

    color: var(--main-nav-font);
    text-decoration: none;
    transition: var(--transition-speed);
    font-weight: normal;

    &.active {
        border-left: solid 3px var(--main-nav-active-border);

        .link-text {
            font-weight: bold;
        }

        * {
            color: var(--main-nav-active-font);
        }
    }
}

.mainnav-item-link:hover {
    color: var(--main-nav-hover-font);
    font-weight: bold;
    text-decoration: none;

    .link-text {
        font-weight: bold;
    }

    * {
        color: var(--main-nav-hover-font);
    }
}

.link-text {
    display: inline;
    margin-left: 1rem;
    font-size: 18px;
    font-weight: 100;
    transition: font-weight 200ms ease;
}

/* Small screens */
@media only screen and (max-width: 767px) {
    .navcontainer {
        bottom: 0;
        width: 100vw;

        height: $navHeightMobile;
    }

    .navcontainer-nav {
        flex-direction: row;
    }

    .mainnav-item-link,
    .mainnav-item-link:not([href]) {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: var(--nav-text-color);
        justify-content: center;

        &.active {
            color: var(--nav-text-color);
            border: none;
        }

        em {
            text-align: center;
        }
    }

    .active [class^='icon-'] {
        color: var(--nav-active-color);
    }

    .link-text {
        text-align: center;
        font-size: 12px;
        font-weight: normal;
        margin-left: 0;
        color: var(--nav-text-color);

        .active & {
            color: var(--nav-active-color);
        }
    }
}
