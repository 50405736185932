﻿@media (max-width: 550px) {
    .profile-details {
        &__heading {
            padding-left: 10px;
            padding-right: 10px;

            .col-avatar {
                order: 1;
            }

            .col-username {
                order: 2;
            }

            .col-email {
                order: 4;
            }

            .col-contact {
                order: 3;
            }

            .col-tel {
                order: 5;
            }

            .col-mobile {
                order: 6;
            }

            div[class^='col'] {
                padding-left: 5px;
                padding-right: 5px;
            }

            .avatar {
                width: 40px;
                height: 40px;
            }
        }
    }

    .edit-avatar {
        div[class^='col'] {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.gestion-selection {
    border-right: solid 1px #ececec;
}

.tab-pane .project-list.projects-more {
    transition: all 0.5s ease-in-out;

    &.hidden {
        height: 0;
        opacity: 0;
    }

    &.show {
        height: 100%;
        opacity: 1;
    }
}

.btn-hidden-projects,
.btn-show-projects {
    color: #3e3e3e;
    text-decoration: none;
    font-weight: 500;

    span {
        font-weight: normal;
    }

    &:hover {
        color: #3e3e3e;
        text-decoration: none;
    }
}
