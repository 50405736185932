html {
    min-height: 100%;
    height: 100%;
}

.login-background {
    padding-left: 0px !important;
    background-size: cover !important;
    @media (min-width: 768px) {
        padding-left: var(--navWidth) !important;
        background-size: cover !important;
    }
}

.login-layout {
    height: 100%;
    margin: 0 20px;
}

.login-container {
    flex-direction: column;
    width: 80%;
    max-width: 400px;
    margin: auto;

    button {
        width: 100%;
    }

    img {
        width: 45%;
        max-width: 45%;
        display: flex;
        margin-left: auto;
        margin-right: auto;

        @media (max-height: 670px) {
            width: 23%;
            max-width: 23%;
            height: auto;
        }
    }
}

@media (min-width: 768px) {
    .login-layout {
        margin-left: 80px;
    }

    .login-container {
        margin-top: auto;
        margin-left: 0;

        img {
            width: 40%;
        }
    }
}
