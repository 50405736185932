.btn {
    margin-right: 2px;
    margin-left: 2px;

    &.btn-wudo {
        padding-bottom: 10px;
    }

    &.btn-notif {
        position: relative;

        .bullet-notif {
            position: absolute;
            width: 25px;
            height: 25px;
            border-radius: 25px;
            background: var(--button-bullet-notif);
            top: -12px;
            right: -12px;
        }
    }

    &.btn-back {
        padding-left: 0;
        color: var(--button2-text-and-border-color);
    }

    &.btn-right {
        float: right;
    }

    &.btn-add-remove {
        position: relative;
        width: var(--btn-add-remove-height-width);
        height: var(--btn-add-remove-height-width);
        border-radius: 50%;
        padding: 0;

        em {
            position: absolute;
            transform: translate(-50%, -50%);
            font-size: var(--btn-add-remove-policy-size);
            font-style: normal;
        }
    }

    &.add {
        line-height: var(--btn-add-remove-line-height-add);

        em {
            top: var(--btn-add-top-position);
            left: var(--btn-add-left-position);
        }
    }

    &.remove {
        line-height: var(--btn-add-remove-line-height-remove);

        em {
            top: var(--btn-remove-top-position);
            left: var(--btn-remove-left-position);
        }
    }

    &.button-gradient-1 {
        background: linear-gradient(90deg, var(--gradient-1-accent-1) 0%, var(--gradient-1-accent-2) 100%);
        color: var(--gradient-1-font);
    }

    &.btn-radius {
        border-radius: 100px;
    }

    &.btn-icon-right {
        position: relative;

        i[class^='icon'] {
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &.btn-lg {
            padding-right: 40px;

            i[class^='icon'] {
                right: 14px;
                width: 20px;
                height: 20px;
            }
        }
    }
    //Primary
    &.btn-primary svg {
        width: 15px;
        height: auto;
    }
    &.btn-primary:hover {
        opacity: 0.9;
    }

    //Outline light
    &.btn-outline-light,
    &.btn-outline-light:active,
    &.btn-outline-light:focus {
        border: solid 1px;
    }
    //Outline primary
    &.btn-secondary svg {
        width: 15px;
        height: auto;
    }

    &.btn-link {
        text-decoration: none;
    }

    &.btn-marging-left {
        margin-left: 3px;
    }

    &.btn-visibility {
        font-size: var(--btn-visibility-create-project);
    }
}
.btn-default {
    background-color: var(--primary-color);
    color: var(--white);
}
.btn-camera {
    margin-bottom: 3px;
}

.btn-folder {
    background-color: var(--button-folder-background);
}

.btn-wall {
    z-index: auto !important;
}

@media (min-width: 576px) {
    .btn-picture {
        width: 200px !important;
        margin-bottom: 5px;
    }
}

@media (max-width: 576px) {
    .btn-picture {
        margin-top: 5px;
    }
}

@media (max-width: 600px) {
    .btn-create {
        position: fixed;
        border-radius: 45px;
        right: 15px;
        bottom: 70px;
        width: 45px;
        height: 45px;
        z-index: 999999999;
        font-size: 25px;
        span {
            display: none;
        }
    }
}

@media (max-width: 576px) {
    .btn-back {
        position: static !important;
        left: 15px;
        border-radius: 45px;
        bottom: 70px;
        width: 45px;
        height: 45px;
        z-index: 999999999;
        span {
            display: none;
        }
        em {
            padding-left: 7px;
        }
    }
}

label[for='userPhoto'],
label[for='solutionLogo'] {
    margin-right: 4px;
    margin-top: 5px;
    margin-bottom: 0px;
}

#userPhoto + button,
#solutionLogo + button {
    margin-top: 5px;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
