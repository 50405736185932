// /!\ DEPRECATED : DO NOT WRITE ANYMORE IN THIS FILE
:root {
    --card-type-project-min-height-1200px: 305px;
    --card-type-project-min-height-992px: 355px;
    --card-type-project-min-height-768px: 480px;

    --btn-visibility-create-project: 1rem;
    --btn-add-remove-height-width: 30px;
    --btn-add-remove-policy-size: 24px;
    --btn-add-remove-line-height-add: 30px;
    --btn-add-remove-line-height-remove: 25px;

    //_____________________________________________________________________________________
    // colors used to customize bootstrap classes defined in _bootstrap-customization.scss |
    //_____________________________________________________________________________________|

    // bootstrap palette
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --subnav-link: #45e4ba;
    --home-project-header: #fff;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;

    // bootstrap elements
    --primary-font: #fff;
    --primary-background: #4095c8;
    --primary-border: #4095c8;
    --primary-hover-font: #fff;
    --primary-hover-background: #4095c8;
    --primary-hover-border: #4095c8;
    --primary-focus-font: #fff;
    --primary-focus-background: #4095c8;
    --primary-focus-border: #4095c8;
    --primary-focus-shadow: rgba(38, 143, 255, 0.5);
    --primary-disabled-font: #fff;
    --primary-disabled-background: #83badb;
    --primary-disabled-border: #83badb;
    --primary-active-font: #fff;
    --primary-active-background: #0062cc;
    --primary-active-border: #0062cc;
    --primary-active-shadow: rgba(38, 143, 255, 0.5);

    --secondary-font: #4095c8;
    --secondary-background: #fff;
    --secondary-border: #4095c8;
    --secondary-hover-font: #fff;
    --secondary-hover-background: #4095c8;
    --secondary-hover-border: #4095c8;
    --secondary-focus-font: #fff;
    --secondary-focus-background: #4095c8;
    --secondary-focus-border: #4095c8;
    --secondary-focus-shadow: rgba(130, 138, 145, 0.5);
    --secondary-disabled-font: #4095c8;
    --secondary-disabled-background: rgb(71, 68, 68);
    --secondary-disabled-border: rgb(71, 68, 68);
    --secondary-active-font: #fff;
    --secondary-active-background: #4095c8;
    --secondary-active-border: #4095c8;
    --secondary-active-shadow: rgba(130, 138, 145, 0.5);

    --dropdown-item-active-font: #fff;
    --dropdown-item-active-background: #4095c8;

    --club-gray-dark: #3c3c3c;
    //TODO: Remove if not used in the future
    --club-gray-other: #6e7074;
    --club-gray: #888888;
    --club-gray-light: #f5f5f5;

    --club-red-hover: #c82333;
    //______________________________________________________________________________________________
    // colors used to customize wudo platform  classes defined in _wudo-platform-customization.scss |
    //______________________________________________________________________________________________|

    // custom palette
    --black: #000;
    --white: #fff;
    --primary: #00678d;
    --secondary: #45e4ba;
    --ternary: #4095c8;
    --font-1: #3c3c3c;
    --font-2: #f5f5f5;
    --shadow-1: rgba(0, 0, 0, 0.125);
    --border-1: #e0e0e0;
    --border-2: #737373;
    --border-3: #754092;
    --gradient-1-accent-1: #ff4d29;
    --gradient-1-accent-2: #fa0e44;
    --gradient-1-font: #fff;
    --gradient-2-accent-1: #65bff4;
    --gradient-2-accent-2: #b4feec;
    --gradient-2-font: #000;
    --gray-light: #ebecec;

    // custom elements
    --main-nav-font: #fff;
    --main-nav-hover-font: #fff;
    --main-nav-active-border: #fff;
    --main-nav-active-font: #fff;
    --top-bar-background: #fff;
    --top-bar-font: #4095c8;
    --top-bar-border-bottom: #4095c8;

    --font-highlight-1: #ffc83d;

    --body-font: #000;
    --paragraph-font: #000;
    --heading1-font: #000;
    --heading2-font: #000;
    --heading3-font: #000;
    --heading4-font: #000;
    --heading5-font: #000;
    --anchor-font: #4095c8;
    --anchor-hover-font: #00678d;

    --button-bullet-notif: #f12e2e;
    --button-folder-background: #fff;

    --card-container-heading5: #00678d;
    --card-project-active-background: #00678d;
    --card-project-active-font: #fff;
    --card-project-active-title-font: #fff;

    --component-user-avatar-background: #ececec;

    --scoop-it-anchor-font: #4095c8;
    --scoop-it-anchor-hover-font: #00678d;
    --scoop-it-background: #f5f5f5;
    --spinner-1: #754092;
    --wizard-item-before-background: #fff;
    --wizard-item-font: #fff;
    --wizard-item-background: #fff;
    --wizard-item-active-font: #fff;
    --wizard-item-active-background: #4095c8;
    --wizard-item-finish-font: #fff;
    --wizard-item-active-background: #4095c8;
    --wizard-item-finish-background: #4095c8;
    --wizard-progress-background: #fff;
    --wizard-progress-gradient-start: #4095c8;
    --wizard-progress-gradient-end: #fff;

    // primeng
    --surface-a: #ffffff;
    --surface-b: #efefef;
    --surface-c: #e9ecef;
    --surface-d: #dee2e6;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #212529;
    --text-color-secondary: #6c757d;
    --primary-color-text: #ffffff;
    --font-family: 'Segoe UI';

    --login-button-background-color: #754092;
    --login-button-color: #fff;
    --clickable-link-color-login: #754092;
}

#login-logo {
    content: url('assets/themes/wudo/images/login-logo-couleurs.png');
}

.login-background {
    background: linear-gradient(115deg, rgb(255, 255, 255) 0%, rgb(254, 247, 255) 60%, rgb(252, 244, 255) 100%);
    background-position: center;
    background-size: auto 100%;
}

.announcement-change-colors {
    background: #ff568b;
    border: solid #ff568b;
    border-width: 1px;
    width: 100% !important;
}
// /!\ DEPRECATED : DO NOT WRITE ANYMORE IN THIS FILE
