body {
    font-size: 14px;
}

.full-height {
    height: 100%;
}

.top-bar .btn-link:hover * {
    font-weight: bold !important;
    color: var(--primary) !important;
}

#main {
    padding-top: var(--topbarHeightMobile);
    padding-bottom: var(--navWidthMobile);
    padding-right: 0;
    padding-left: 0;

    @media (min-width: 768px) {
        padding-top: var(--topbarHeight);
        //padding-bottom: var(--navWidth);
    }
}

.view-name {
    color: var(--primary-color);
    margin-top: 10px;

    @media (max-width: 620px) {
        font-size: 1.2rem;
        margin-bottom: 4px;
    }
}

.box-shadow {
    -webkit-box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
}

.description-label {
    background: #f7f7fe;
    color: #6c757d;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

.form-group :disabled {
    cursor: not-allowed;
}

html {
    min-height: 100%;
    height: 100%;
}

.fw-b {
    font-weight: bold;
}

.main-container {
    width: 100%;
}

.ta-r {
    text-align: right;
}

.ta-c {
    text-align: center;
}

.loading-dots:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: var(--primary);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 var(--primary), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 var(--primary), 0.5em 0 0 var(--primary);
    }
}

.badge {
    img {
        width: 10px;
        padding-bottom: 4px;
    }
}

.z-index-0 {
    z-index: 0 !important;
}

.text-link,
.mailto-link,
.mailto-link * {
    text-decoration: none;
    color: var(--clickable-link-color) !important;
    cursor: pointer;
}

.text-link:hover,
.mailto-link:hover,
.mailto-link:hover * {
    text-decoration: none;
    color: var(--clickable-link-color) !important;
}

.primary-1 {
    color: #505050 !important;
}

.secondary-1 {
    color: var(--secondary);
}

.gradient-1 {
    background: var(--gradient-1);
}

.secondary-2 {
    color: var(--secondary);
}

body {
    background-color: var(--font-2);
}

$space: 25px;

.space {
    margin-top: $space;
    margin-bottom: $space;
}

.space-light {
    margin-top: calc(#{$space} - 10px);
    margin-bottom: calc(#{$space} - 10px);
}

.space-large {
    margin-top: calc(#{$space} + 5px);
    margin-bottom: calc(#{$space} + 5px);
}

.space-right {
    margin-right: 20px;
}

.space-left {
    margin-left: 20px;
}

.padding-none {
    padding: 0;
}

.space-top {
    margin-top: 10px;
}

.space-bottom {
    margin-bottom: 20px;
}

.tooltip {
    z-index: 1151 !important;
}

.custom-confirmPopup {
    max-width: 50%;
    .p-confirm-popup-message {
        white-space: pre-line;
    }
}

a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

a.nostyle:hover {
    cursor: pointer;
}

.community-container {
    min-height: 80vh;
    max-height: 100vh;

    & .p-datatable {
        height: 100vh;
    }
}

.custom-edit-member-menu {
    .p-menuitem-link {
        .p-menuitem-text {
            font-weight: bold;
            color: var(--club-gray-dark);
            font-size: 0.8em;
        }
        .p-menuitem-icon {
            font-weight: bold;
            color: var(--club-gray-dark);
        }
    }
    .menuItemDelete {
        .p-menuitem-link {
            .p-menuitem-text {
                color: var(--red) !important;
            }
            .p-menuitem-icon {
                color: var(--red) !important;
            }
        }
        .p-menuitem-link:hover {
            background-color: var(--club-red-hover) !important;
            .p-menuitem-text {
                color: var(--club-gray-light) !important;
            }
            .p-menuitem-icon {
                color: var(--club-gray-light) !important;
            }
        }
    }
}
