.form-login {
    border-color: var(--border-3) !important;
}

.form-control {
    @media (max-width: 575.98px) {
        font-size: 0.9rem;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

.form-header {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 25;
    top: 80px;
    background-color: var(--font-2);

    @media (max-width: 575.98px) {
        top: 70px !important;
    }
}

.form-group.date {
    position: relative;

    .input-group-addon {
        position: absolute;
        right: 5px;
        bottom: 5px;

        [class^='icon-'] {
            font-size: 20px;
        }
    }
}

// tooltip on wallet Wukoins
.tooltip-inner {
    max-width: 400px !important;
    height: 90px;
    font-size: 14px;
    padding: 10px 15px 10px 20px;
    background: var(--white);
    color: var(--font-1);
    border: 1px solid var(--border-2);
    text-align: left;
}

// admin_user_edit
.admin-wukoins-before,
.admin-wukoins-after {
    display: flex;
    flex-flow: column;

    p {
        display: inline-block;
        font-weight: bold;
        border: 1px solid var(--primary);
        border-radius: 25px;
        color: var(--primary);
        width: auto;
        margin: auto;
        padding: 1px 6px;
        min-width: 27px;
    }
}

.admin-wukoins-after {
    p {
        border: 1px solid var(--secondary);
        color: var(--secondary);
    }
}

.site-url {
    white-space: nowrap;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-form {
    padding-bottom: 50px;
}

.login-text {
    color: var(--border-2);
}
