.community-list-item-role-icon > .icon {
    color: white !important;
    font-size: 15px !important;
}

.list-community {
    margin: 5px 0 0 0;
    padding: 0;
    display: block;

    > li {
        list-style: none;
        margin-right: 10px;
    }
}

.favorite {
    color: var(--font-highlight-1);
}
