﻿.place-icon {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 25px;
    font-size: 25px;

    img {
        display: inline-block;
    }

    &.icon-trash {
        height: 30px;
        line-height: 20px;
    }
}
