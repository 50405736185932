.side-folder {
    z-index: 10;
    padding: 1px 10px 0 5px;
    color: #1b4145;
    margin-bottom: 40px;

    @media (min-width: 992px) {
        background-color: white;
        position: fixed;
        top: 80px;
        bottom: 0;
        overflow-y: auto;
        width: 275px;
        border-right: 1px solid #e7e7e7;
        font-size: 16px;
        margin-bottom: 0;
    }

    h2 {
        font-size: 16px;
        margin-top: 37px;

        @media (max-width: 991.9px) {
            display: none;
        }

        @media (min-height: 700px) {
            margin-bottom: 125px;
        }
    }
}

.side-folder-container {
    @media (max-width: 991.9px) {
        color: black;
    }
}
