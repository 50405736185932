﻿.img-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 5px;

    img {
        width: 100%;
        height: 100% !important;
        object-fit: contain;
    }
}
