.filter-row {
    margin-top: 15px;
    margin-bottom: 15px;

    .search-input {
        position: relative;

        .icon-search {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            margin: auto;
            display: block;
            width: 20px;
            height: 20px;
        }

        input[type='search'] {
            padding-left: 40px;
        }
    }
}

@media (max-width: 768px) {
    .search-input {
        margin-top: 15px;
    }
}

.card-filters {
    overflow: hidden;

    .card-header {
        background: var(--white);
        padding: 10px;
    }

    .filter-section {
        width: 100%;
        border-bottom: solid 2px var(--primary);

        &__heading {
            padding: 10px;
            color: #505050;
            background: var(--font-2);
            font-weight: 500;
        }

        &__accordion-header {
            padding: 10px;
            position: relative;

            .btn-link {
                color: var(--font-1);
                font-weight: 500;
                padding: 0;
                text-overflow: ellipsis;
                max-width: 90%;
                overflow: hidden;
                white-space: nowrap;

                &:hover,
                &:focus {
                    text-decoration: none;
                }

                &.collapsed {
                    em.icon-down {
                        display: none;
                    }

                    em.icon-up {
                        display: inline;
                    }
                }

                em.icon-down {
                    display: inline;
                }

                em.icon-up {
                    display: none;
                }
            }
        }

        &__body {
            padding: 10px;

            label {
                margin-bottom: 0;
                display: initial;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    padding: 5px 0;
                }
            }
        }
    }
}

@media (min-width: 550px) {
    .main-card-filters.top,
    #content-list.top {
        position: -webkit-sticky;
        position: sticky;
    }

    .main-card-filters.bottom,
    #content-list.bottom {
        position: fixed;
        bottom: 75px;
    }
}

@media (max-width: 550px) {
    .btn-open-filters {
        margin-top: 20px;
    }

    .main-card-filters {
        position: fixed;
        top: 0px;
        left: 0;
        right: 0;
        z-index: 999;
        height: 93.5vh;
        border-radius: 0;
        transform: translateX(100%);
        box-shadow: none;
        transition: all 0.4s ease-in-out;
        background: var(--shadow-1);
        padding: 10px;
        overflow: auto;

        &.filters-show {
            transform: translateX(0%);
        }

        .close-filters {
            border-bottom: solid 1px var(--border-1);
        }

        .btn-close-filters {
            color: var(--black);

            img {
                width: 15px;
                height: 15px;
            }
        }
    }
}
