﻿.ql-toolbar {
    border-radius: 0.25rem;
}

.ql-container {
    border-radius: 0.25rem;
    height: 180px;
}

.ql-container.ngx-quill-view {
    height: auto;
}

.ql-editor {
    // font-family : 'WudoPlatform', 'Roboto', 'Roboto Lt';
    // font-size: 14px;

    &:focus {
        border: 1px solid #80bdff !important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    }
}

.ql-snow .ql-tooltip[data-mode='link'] {
    z-index: 10;
    left: 30px !important;
}

.ql-french > .ql-snow .ql-tooltip[data-mode='link']::before {
    content: 'Saisissez une URL :';
}

.ql-french > .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Enregistrer';
}

.ql-french > .ql-snow .ql-tooltip::before {
    content: 'Visiter :';
}

.ql-french > .ql-snow .ql-tooltip a.ql-action::after {
    content: 'Modifier';
}

.ql-french > .ql-snow .ql-tooltip a.ql-remove::before {
    content: 'Supprimer';
}
