#scoopit-theme_full.scoopit-embed-full-theme {
    a {
        color: var(--clickable-link-color) !important;
    }

    a:hover {
        color: var(--clickable-link-color) !important;
    }

    #scoopit-wrapper {
        background-color: var(--font-2) !important;
        max-width: 100% !important;

        padding: 0 !important;

        .scoopit-fulltheme-scoops .scoopit-fulltheme-scoop {
            background-color: #fff !important;
            background-clip: border-box !important;
            border: none !important;
            border-radius: 10px !important;
            -webkit-box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);

            .scoopit-fulltheme-scoop-title {
                font-size: 18px !important;
            }

            .scoopit-fulltheme-scoop-source {
                font-size: 12px !important;
            }

            .scoopit-fulltheme-scoop-content {
                font-size: 14px !important;
            }

            .scoopit-fulltheme-scoop-curationcomment {
                font-size: 14px !important;
            }
        }
    }
}
