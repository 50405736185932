.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-wrapper {
    margin: auto;

    width: 240px;
    max-width: 100%;

    p {
        text-align: center;
    }
}

.frameborder-less {
    border: 0;
}
