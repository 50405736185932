﻿.project-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    width: 100%;
    background: #fff;

    &__photo {
        height: 300px;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__join {
        background: #414141;
        color: #fff;
    }
}

@media (max-width: 600px) {
    .project-header {
        &__photo {
            height: 100px;
        }

        .join-btn {
            text-align: center !important;
        }

        .block-btn-join-project {
            margin-top: 1.5rem;
            padding-top: 1.3rem;
            border-top: solid 1px #333333;
        }
    }
}
