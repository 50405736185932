.top-bar {
    background: white; //#ececec; //A définir
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 400;
    padding: 9px 12px;
    box-shadow: 0px 7px 15px rgb(0 0 0 / 10%);
    height: var(--topbarHeightMobile);

    @media (min-width: 576px) {
        padding: 15px;
        height: var(--topbarHeight);
    }

    //Wujoins Stack
    .my-wukoins {
        color: #1197cd;
    }

    .top-icon {
        font-size: 30px;
        padding-left: 2px;
    }
    //User name & Avatar
    .user-info {
        display: flex;
        align-items: center;
        color: var(--primary-color);

        .btn-link:hover {
            text-decoration: none;

            .avatar {
                box-shadow: 0px 0 0px 2px var(--primary-color);
            }
        }

        .btn-link:active,
        .btn-link:focus {
            text-decoration: none;

            .avatar {
                box-shadow: 0px 0 0px 3px var(--primary-color);
            }
        }

        .avatar {
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            margin-left: 10px;
            display: inline-block;

            img {
                width: 50px !important;
            }
        }

        .no-photo {
            display: inline-block !important;
            padding: 0.6rem;
        }
    }
}

@media (max-width: 575.98px) {
    .my-wukoins {
        padding: 0 !important;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            vertical-align: top;
            max-width: calc(100vw - 275px);
        }
    }
}

.wallet-contribution-badge {
    position: absolute;
    top: -5px;
    right: 10px;
    padding: 0px 5px;
    border-radius: 55px;
    background: red;
    -webkit-background-clip: padding-box;
    color: red;
    text-decoration: none;
    font-size: 12px;
}

.popover {
    max-width: 300px; // Au lieu de 300px fixe
    width: 95vw; // Prend presque toute la largeur de l'écran mobile
    word-wrap: break-word;
    margin: 0 auto; // Centre le popover

    .popover-header {
        background-color: var(--primary-color);
        color: white;
        border-bottom: 0;
        border-radius: 0;
        padding: 8px;
        font-size: 14px; // Taille réduite pour mobile
    }

    .popover-body {
        padding: 8px;
        font-size: 13px; // Taille réduite pour mobile

        .list-group {
            margin-bottom: 0;
            .list-group-item {
                padding: 8px 12px; // Padding réduit pour mobile
                border: 0;
                border-bottom: 1px solid #f1f1f1;
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .popover {
        width: 75%;
    }
}
