.spinner-border.text-info {
    color: var(--primary-color) !important;
}

// Alert

// Badge
.badge-primary {
    color: var(--button1-text-color);
    background-color: var(--primary-color);
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: var(--primary-hover-font);
    background-color: var(--primary-hover-background);
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--primary-focus-shadow);
}
// Breadcrumb

// Button
.btn:hover {
    color: #212529;
}

.btn:focus,
.btn.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary-color), 0.5);
}

.btn-primary {
    color: var(--button1-text-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-primary:hover {
    color: var(--button1-text-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-primary:focus,
.btn-primary.focus {
    color: var(--button1-text-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: 0 0 0 0.2rem var(--primary-color-shadow);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: var(--button1-text-color);
    background-color: var(--primary-color-shadow);
    border-color: var(--primary-color-shadow);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: var(--button1-text-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--primary-color-shadow);
}

.btn-secondary {
    color: var(--button2-text-and-border-color);
    background-color: #fff;
    border-color: var(--button2-text-and-border-color);
}

.btn-secondary:hover {
    color: #fff;
    background-color: var(--button2-text-and-border-color);
    border-color: var(--button2-text-and-border-color);
}

@media (hover: none) {
    .btn-secondary:hover {
        color: #fff;
        background-color: var(--button2-text-and-border-color);
        border-color: var(--button2-text-and-border-color);
    }
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #fff;
    background-color: var(--button2-text-and-border-color);
    border-color: var(--button2-text-and-border-color);
    box-shadow: 0 0 0 0.2rem var(--button2-color-shadow);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: var(--button2-text-and-border-color);
    background-color: var(---button2-color-shadow);
    border-color: var(--button2-color-shadow);
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: var(--button2-text-and-border-color);
    border-color: var(--button2-text-and-border-color);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary-active-shadow);
}
// Button group

// Card

// Carousel

// Collapse

// Dropdown
.dropdown-item.active,
.dropdown-item:active {
    color: var(--button1-text-color) !important;
    background-color: var(--primary-color);
}

// Forms

// Input group

// Jumbotron

// List group

// Media object

// Modal

// Navs

// Navbar

// Pagination

// Popovers

// Progress

// Scrollspy

// Spinners

// Toasts

// Tooltips
