//Mobile layout
@media (max-width: 575px) {
    .left {
        order: 2;
    }

    .wall,
    .news {
        & > .col-12 {
            padding: 0;
        }
    }

    .card-body {
        padding: 1rem;
    }

    .card-footer {
        padding: 0.75rem;
    }

    .card-filter {
        max-height: calc(50vh - 75px);
        overflow: auto;
    }
}

.wall {
    .form-control {
        border-color: var(--white);
    }

    .card {
        /*margin-bottom: 10px;*/
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .btn-link {
        background-color: var(--white);
    }

    &.fs {
        font-size: 24px;
    }

    .project-pointer {
        cursor: pointer;
    }
}

// card on documents page
.card-container {
    display: flex;
    justify-content: space-around;
    padding: 0.7rem 0.7rem 0.4rem;

    svg {
        flex-shrink: 0;
    }

    h5 {
        color: var(--card-container-heading5);
        margin-bottom: 0;
    }
}

.card-folder {
    p {
        margin-bottom: 0;
    }
}

//card on documents page on mobile
@media (max-width: 991.98px) {
    .mobile-files {
        border-top: 1px solid var(--shadow-1);

        .space-bottom {
            margin-bottom: 0;
        }

        .card-document {
            margin-top: 10px;
        }

        .card-container {
            & > svg {
                //float: left;
                margin-right: 10px;
                width: 25px;
            }

            h5 {
                font-size: 15px;
                margin-bottom: 5px;
            }

            p:not(.text-right) {
                display: none;
            }

            @media (max-width: 430px) {
                p {
                    display: none;
                }
            }
            // size & download
            .items-right {
                display: flex;
                justify-content: flex-end;

                .btn-folder {
                    line-height: 1;
                }
            }
        }
    }
}

.text-wraper {
    width: 100%;
}

@media (max-width: 575.98px) {
    .media {
        .avatar {
            margin-right: 0.5rem !important;
        }
    }
}

.wukoins-stack {
    font-size: 48px;
    display: inline-flex;
}

.wukoins-main-card {
    border-left: 5px solid var(--primary);
}

.wukoin-services-card {
    padding-left: 0;

    &:hover {
        transform: scale(1.05);
        transition: 0.2s;
    }

    .card-container {
        border-left: 5px solid var(--secondary);
    }
}

.wukoin-activity-card {
    padding-left: 0;

    .month-title {
        font-size: 1.25rem;
        font-weight: 500;
        margin: 5px 0 5px 0;
    }
}

@media (max-width: 800px) {
    .picto-wukoins {
        max-width: 50px;
        height: auto;
    }
}

@media (min-width: 850px) and (max-width: 1000px) {
    .picto-wukoins {
        width: 80px;
        height: auto;
    }
}

.card {
    .avatar:hover {
        img {
            transition: all 0.3s ease-in-out;
            transform: scale(1.2);
        }
    }
}

//img avatar for social feed
.card-body {
    .avatar {
        width: 100px !important;
        height: 100px !important;
        flex-shrink: 0;
    }

    .img-pointer {
        cursor: pointer;
    }

    .txt-pointer {
        cursor: pointer;
    }

    .txt-pointer:hover {
        color: var(--primary);
    }
}

.card {
    border-radius: 10px;
    border: none;
    overflow: hidden;
    &.with-border {
        border: solid 1px var(--border-1);
    }

    hr {
        margin: 0;
    }

    &.card-project-active {
        background: var(--primary-color);
        color: var(--button1-text-color);

        .list-group-item {
            background: transparent;
        }

        h3 {
            color: var(--button1-text-color) !important;
        }
    }
}

.card-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
