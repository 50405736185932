.user-list-row {
    margin-top: 15px;

    .card {
        border-radius: 10px;
        border: none;
    }

    .no-gutters {
        .avatar {
            margin-right: 10px;
        }
    }

    .avatar {
        width: 80px;
        height: 80px;
    }

    .user-name {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
    }

    .place-icon {
        width: 30px;
        font-size: 25px;
        text-align: center;
        display: inline-block;
    }
}

@media (max-width: 550px) {
    .user-list-row {
        .avatar {
            width: 40px;
            height: 40px;
        }

        .place-icon {
            font-size: 25px;
            margin-right: 10px;
        }
    }
}

.avatar {
    vertical-align: middle;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    background: var(--component-user-avatar-background);
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary);
    text-align: center;

    &.no-photo {
        display: table-cell;

        span {
            vertical-align: middle;
            display: inline-block;
            line-height: 2rem;
        }
    }

    img {
        width: 100%;
    }

    &.avatar-lg {
        width: 150px !important;
    }

    &.avatar-xs {
        width: 40px !important;
    }
}

@supports (object-fit: cover) {
    .avatar {
        img {
            height: 100%;
            object-fit: cover;
        }

        &.avatar-lg {
            height: 150px !important;
        }

        &.avatar-xs {
            height: 40px !important;
        }
    }
}
