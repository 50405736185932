// Segoe UI
@font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI'), local('SegoeUI'), url('/assets/fonts/text/SegoeUI/SegoeUI.woff2') format('woff2'),
        url('/assets/fonts/text/SegoeUI/SegoeUI.woff') format('woff'),
        url('/assets/fonts/text/SegoeUI/SegoeUI.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Light'), local('SegoeUI-Light'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Light.woff2') format('woff2'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Light.woff') format('woff'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Bold'), local('SegoeUI-Bold'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Bold.woff2') format('woff2'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Bold.woff') format('woff'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI Semibold'), local('SegoeUI-Semibold'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Semibold.woff2') format('woff2'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Semibold.woff') format('woff'),
        url('/assets/fonts/text/SegoeUI/SegoeUI-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

// Raleway
@font-face {
    font-family: 'Raleway';
    src: local('Raleway Light'), local('Raleway-Light'),
        url('/assets/fonts/text/Raleway/Raleway-Light.woff2') format('woff2'),
        url('/assets/fonts/text/Raleway/Raleway-Light.woff') format('woff'),
        url('/assets/fonts/text/Raleway/Raleway-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Regular'), local('Raleway-Regular'),
        url('/assets/fonts/text/Raleway/Raleway-Regular.woff2') format('woff2'),
        url('/assets/fonts/text/Raleway/Raleway-Regular.woff') format('woff'),
        url('/assets/fonts/text/Raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Bold'), local('Raleway-Bold'),
        url('/assets/fonts/text/Raleway/Raleway-Bold.woff2') format('woff2'),
        url('/assets/fonts/text/Raleway/Raleway-Bold.woff') format('woff'),
        url('/assets/fonts/text/Raleway/Raleway-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
        url('/assets/fonts/text/Raleway/Raleway-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/text/Raleway/Raleway-SemiBold.woff') format('woff'),
        url('/assets/fonts/text/Raleway/Raleway-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

// Roboto
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'), url('/assets/fonts/text/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('/assets/fonts/text/Roboto/Roboto-Regular.woff') format('woff'),
        url('/assets/fonts/text/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'), url('/assets/fonts/text/Roboto/Roboto-Bold.woff2') format('woff2'),
        url('/assets/fonts/text/Roboto/Roboto-Bold.woff') format('woff'),
        url('/assets/fonts/text/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light'), local('Roboto-Light'),
        url('/assets/fonts/text/Roboto/Roboto-Light.woff2') format('woff2'),
        url('/assets/fonts/text/Roboto/Roboto-Light.woff') format('woff'),
        url('/assets/fonts/text/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'),
        // Roboto Semibold does not exist => replaced by Medium
        url('/assets/fonts/text/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('/assets/fonts/text/Roboto/Roboto-Medium.woff') format('woff'),
        url('/assets/fonts/text/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
