.onboarding {
    img.boarding {
        height: 150px;
        width: auto;
    }
}

.boarding-avatar {
    height: 100px;
    border-radius: 50%;
}

.onboarding-bullets {
    padding: 0;
    > li {
        list-style-type: none;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        &:before {
            content: ' ';
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background: var(--secondary);
            position: absolute;
            left: 0;
            text-align: center;
            color: #fff;
        }
        &:first-child:before {
            content: '1';
        }
        &:nth-child(2):before {
            content: '2';
        }
        &:nth-child(3):before {
            content: '3';
        }
        &:last-child:before {
            content: '✓';
        }
    }
}

input[type='checkbox'] {
    margin-right: 7px;
    margin-bottom: 0px;
}

@media (max-width: 600px) {
    .boarding-avatar {
        margin-bottom: 1.5rem;
    }
}
