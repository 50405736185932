/* modal animation */
.modal-content,
.modal-content-small {
    animation-name: fadeInModal;
    animation-duration: 0.4s;
    transform: translate3d(0, 0, 0);
}

@keyframes fadeInModal {
    0% {
        opacity: 0;
        transform: translate3d(0, 40px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.modal-body {
    // fix sur pb scroll en mobile
    //max-height: calc(100vh - 210px);
    // /!\ Si régression observer sur les modals en général, rétablir calcul du max-height et déporter la propriété "max-height: none !important" dans "_onboarding.scss"
    max-height: none !important;
    overflow-y: auto;
}

.modal {
    z-index: 9999;
}

.modal-image {
    width: 100%;
    height: 200px;

    > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
