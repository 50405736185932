﻿.project-list {
    .img-container {
        height: 135px;
    }
}

@media (max-height: 750px) and (max-width: 600px) {
    .project-container-step {
        overflow: auto;
        height: 78vh;
    }
}

@media (min-height: 751px) and (max-height: 850px) and (max-width: 600px) {
    .project-container-step {
        overflow: auto;
        height: 78vh;
    }
}

@media (max-width: 600px) {
    .footer-create-project {
        position: fixed;
        bottom: 90px;
    }
}


